import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: poppins;
  overflow: ${(props) => (props.mobileMenu ? "hidden" : "auto")};
}

button {
  font-family: poppins;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

}



html {
  background-color: #FFFFFF;
}

@keyframes color-transform {
  0% {
    opacity: 0.4;
  }
  30% {
    opacity: 1;
    scale: 120%;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}

@keyframes fade-grow-in {
  0% {
    transform: scaleY(.80);
    opacity: 0.5;
    box-shadow: none;
  }

  60% {
    transform: scaleY(100%);
  }
  100% {
    transform: scale(100%);
    opacity: 1;
  }
}

@keyframes fade-grow-in-about {
  0% {
    transform: scale(0.6, 0.3);
    opacity: 0.5;
  }
  60% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(0.6);
    opacity: 1;
  }
}

@keyframes fade-grow-in-vertical {
  0% {
    transform: translateY(15%) scaleY(70%);
    opacity: 0.5;
    box-shadow: none;
  }

  60% {
    transform: scaleY(100%);
  }
  100% {
    opacity: 1;
  }
}

@keyframes grow-in-vertical-complete {
  0% {
    transform: translateY(50%) scaleY(0%);
  }

  100% {
    transform: translateY(0%) scaleY(100%);
  }
}

@keyframes mobile-menu-appear {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes mobile-menu-disappear {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes fadeIn {
	0% {
			opacity: 0;
	}
	100% {
			opacity: 1;
	}
}

@keyframes  slideIn {
	0% {
			transform: translateY(50px);
	}
	100% {
			transform: translateY(0px);
	}
}
`;
