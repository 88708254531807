import styled from "styled-components";
import { MediaQuery } from "../constants";
import { WorkImageBox } from "./WorkElements";

const ProjectOverviewContainer = styled.div.attrs({
	className: "project-overview-container",
})``;

const ProjectOverviewHeaderContainer = styled.div.attrs({
	className: "project-overview-header-container",
})`
	width: 100%;
	padding-top: 2%;
	@media ${MediaQuery.tablet} {
		display: flex;
		align-items: flex-end;
	}
`;

const TitleColumn = styled.div.attrs({
	className: "title-column",
})`
	display: flex;
	flex-direction: column;
	padding: 1rem 0 1.5rem 0;
	@media ${MediaQuery.tablet} {
		padding-right: 9rem;
		padding-bottom: 0;
		flex-basis: 70%;
	}
`;

const ProjectTitle = styled.div.attrs({
	className: "project-title",
})`
	font-size: clamp(1.6rem, 5vw, 6rem);
	font-weight: 500;
`;

const ProjectDescription = styled.div.attrs({
	className: "project-description",
})`
	font-size: clamp(0.8rem, 1.3vw, 1.6rem);
	&:after {
		content: "I have been fortunate to have been recognized through awards and patents for my work in the technology and digital media sectors.";
	}
	@media ${MediaQuery.tablet} {
		&:after {
			content: ${`
				A platform giving artists and brands easy access to the metaverse
      	through the creation of virtual worlds and augmented experiences.
      	Auggy gives each brand their own world on the blockchain where their
      	fans can explore exclusive content & unlock games, prizes and more!
			`};
		}
	}
	@media ${MediaQuery.laptopS} {
		padding-right: 5rem;
	}
	@media ${MediaQuery.laptopM} {
		padding-right: 10rem;
	}
	@media ${MediaQuery.desktopL} {
		padding-right: 30rem;
	}
`;

const ProjectSpecsColumn = styled.div.attrs({
	className: "project-specs-column",
})`
	font-size: clamp(0.8rem, 1.3vw, 1.6rem);
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: 100%;
`;

const SpecSpacer = styled.div.attrs({
	className: "spec-spacer",
})`
	height: 1.5rem;
	@media ${MediaQuery.laptopS} {
		height: 2rem;
	}
`;

const SpecItem = styled.div.attrs({
	className: "spec-item",
})`
	display: flex;
	flex-direction: column;
	${(props) => props.double === true && "margin-left: 20%;"}
	flex-basis: 30%;
`;

const ExpertiseBlock = styled.div.attrs({
	className: "expertise-block",
})`
	&:after {
		content: "Mobile Design, Web Design, UX/UI, 3D";
	}
	@media ${MediaQuery.tablet} {
		&:after {
			content: "Mobile Design, Web Design, 3D, Augmented Reality,  branding, web 3.0";
		}
	}
`;

const BoldSpec = styled.div.attrs({
	className: "bold-spec",
})`
	font-weight: 600;
`;

const RowWrapper = styled.div.attrs({
	className: "row-wrapper",
})`
	display: flex;
`;

const ProjectOverviewBoxContainer = styled.div.attrs({
	className: "project-overview-box-container",
})`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-top: 2.5rem;
	@media ${MediaQuery.tablet} {
		padding-top: 4rem;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	}
	@media ${MediaQuery.mbp14} {
		padding-top: 6rem;
	}
`;

const ProjectOverviewBox = styled(WorkImageBox).attrs({
	className: "project-overview-box",
})`
	height: 14rem;
	margin: 0 0 1rem 0;
	padding: 0;
	@media ${MediaQuery.tablet} {
		width: 49%;
		height: clamp(17rem, 33vw, 50rem);
	}
`;

const MyRoleContainer = styled.div.attrs({
	className: "my-role-container",
})`
	display: flex;
	flex-direction: column;
	padding-top: 1rem;
	@media ${MediaQuery.tablet} {
		padding-top: 3rem;
		flex-direction: row;
	}
	@media ${MediaQuery.laptopM} {
		padding-top: 6rem;
	}
`;

const MyRoleTitle = styled.div.attrs({
	className: "my-role-title",
})`
	flex-basis: 50%;
	font-size: clamp(1.4rem, 3vw, 3.2rem);
`;

const MyRoleDescription = styled.div.attrs({
	className: "my-role-description",
})`
	flex-basis: 50%;
	font-size: clamp(0.7rem, 1.2vw, 1.8rem);
	padding-top: 0.5rem;
	&:after {
		content: "I have been fortunate to have been recognized through awards and patents for my work in the technology and digital media sectors.";
	}
	@media ${MediaQuery.tablet} {
		&:after {
			content: "Worked with stakeholders to develop the product concept and requirements. Created user stories, conducted user interviews and user journey maps. Developed branding and created a custom responsive web app, 3D assets and guided and led a small team of designers. I also travelled to and lived in Los Angeles for two months to work closely with the team while building this experience. ";
		}
		padding-left: 2rem;
	}
`;

const LargeProjectOverviewBox = styled(WorkImageBox).attrs({
	className: "large-project-overview-box",
})`
	margin-top: 2rem;
	margin-bottom: 6rem;
	height: 10rem;
	@media ${MediaQuery.tablet} {
		margin-top: 4rem;
		height: 40rem;
	}

	@media ${MediaQuery.laptopS} {
		height: 50rem;
	}

	@media ${MediaQuery.laptopM} {
		height: 70rem;
	}

	@media ${MediaQuery.desktopM} {
		height: 90rem;
	}
`;

export {
	ProjectOverviewContainer,
	ProjectOverviewHeaderContainer,
	TitleColumn,
	ProjectTitle,
	ProjectDescription,
	ProjectSpecsColumn,
	SpecSpacer,
	SpecItem,
	ExpertiseBlock,
	BoldSpec,
	RowWrapper,
	ProjectOverviewBoxContainer,
	ProjectOverviewBox,
	MyRoleContainer,
	MyRoleTitle,
	MyRoleDescription,
	LargeProjectOverviewBox,
};
