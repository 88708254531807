import React from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {
	AboutMeHeroWrapper,
	AboutMeHeroContainer,
	AboutMeName,
	AboutMeDescription,
	AboutMeParagraph,
	HorizontalLine,
} from "./AboutMeElements";

const aboutMeTexts = [
	{
		id: 1,
		text: "I have experience working in both the public and private sector and specialize in mobile app design, web design, branding and content creation utilizing best practices. My versatile skillset allows me to look at your brand on a wholistic level and create consistent design libraries that can be used and iterated on for years to come!",
	},
	{
		id: 2,
		text: "I have managed large and small teams of designers and developers to successfully launch digital products. I also love providing guidance and advisory to tech companies within the space and speaking at conferences & on panels.",
	},

	{
		id: 3,

		text: "Personally, I love to cook, play sports like golf and tennis with friends and travel. Fortunately my work has allowed me to explore and travel while working on certain projects. I’ve worked remotely as a digital nomad for 6 years, but alway enjoy connecting in person when I have the opportunity to!",
	},
];

export default function AboutMeHero() {
	React.useEffect(() => {
		setTimeout(() => {
			gsap.to(".about-me-hero-container", {
				scrollTrigger: {
					trigger: ".about-me-hero-container",
					start: "top 75%",
					toggleActions: "play none none none",
				},
				opacity: 1,
				transform: "translateY(0px)",
				duration: 1,
			});
		}, 200);
		ScrollTrigger.refresh();
		return () => {
			ScrollTrigger.getAll().forEach((trigger) => {
				trigger.kill();
			});
		};
	}, []);

	return (
		<AboutMeHeroWrapper>
			<AboutMeHeroContainer>
				<AboutMeName>About Me</AboutMeName>

				<AboutMeDescription>
					{aboutMeTexts.map((text) => (
						<AboutMeParagraph key={text.id}>{text.text}</AboutMeParagraph>
					))}
				</AboutMeDescription>
			</AboutMeHeroContainer>
			<HorizontalLine />
		</AboutMeHeroWrapper>
	);
}
