import React from "react";
import styled from "styled-components";
import { MediaQuery } from "../../constants";

// Get ColorPair width percentage based on index and total number of colors
const getWidthPercentFromIndex = (index, colorLength) => {
	switch (index) {
		case 0:
			return "34.06%";
		case 1:
			return "25.07%";
		case 2:
			return "20.44%";
		case 3:
			return "20.43%";
		default:
			switch (colorLength) {
				case 8:
					return "25.00%";
				case 7:
					return "33.33%";
				default:
					return "25.00%";
			}
	}
};

const ColorSchemeContainer = styled.section`
	display: flex;
	flex-wrap: wrap;
	height: 400px;
	@media ${MediaQuery.tablet} {
		height: 540px;
	}
	@media ${MediaQuery.laptopM} {
		height: 848px;
	}
`;

const ColorSchemeThirdContainer = styled.section`
	display: grid;
	grid-template-columns: ${({ colorLength }) =>
		colorLength === 2 ? "1fr 1fr" : "1fr"};
	@media ${MediaQuery.tablet} {
		display: none;
	}
`;

const ColorPair = styled.div`
	width: calc(33.33%);
	@media ${MediaQuery.tablet} {
		width: ${({ index, colorLength }) =>
			getWidthPercentFromIndex(index, colorLength)};
	}
`;

const PrimaryColorBlock = styled.div`
	color: ${({ color }) => color};
	background-color: ${({ backgroundColor }) => backgroundColor};
	/* when secondary color is absent, make PrimaryColorBlock occupy all space in ColorPair  */
	height: ${({ colorData }) => (colorData.secondary ? "120px" : "170px")};
	padding: 15px 25px;
	font-weight: 700;
	font-size: clamp(0.6rem, 1.1vw, 3rem);
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	h4 {
		margin-bottom: 10px;
	}
	@media ${MediaQuery.tablet} {
		height: ${({ colorData }) => (colorData.secondary ? "160px" : "230px")};
		padding: 20px 35px;
	}

	@media ${MediaQuery.laptopM} {
		height: ${({ colorData }) => (colorData.secondary ? "241px" : "364px")};
		padding: 30px 48px;
	}
`;

const PrimaryColorContent = styled.div`
	font-weight: 400;
	font-size: clamp(0.4rem, 1vw, 2.8rem);

	p {
		margin-bottom: 5px;
	}
`;

const SecondaryColorBlock = styled.div`
	color: ${({ color }) => color};
	background-color: ${({ backgroundColor }) => backgroundColor};
	height: 30px;
	padding: 10px 25px;
	font-size: clamp(0.6rem, 1.1vw, 3rem);
	@media ${MediaQuery.tablet} {
		height: 30px;
		padding: 20px 35px;
	}

	@media ${MediaQuery.laptopM} {
		height: 63px;
		padding: 30px 48px;
	}
`;

function SectionColorScheme({ data }) {
	const colorsData = data.colors;
	const thirdRow = data.colors.slice(6);

	return (
		<>
			<ColorSchemeContainer>
				{colorsData.map((color, index) => (
					<ColorPair
						key={color.name}
						colorLength={colorsData.length}
						index={index}>
						<ColorsContent color={color} />
					</ColorPair>
				))}
			</ColorSchemeContainer>

			<ColorSchemeThirdContainer colorLength={thirdRow.length}>
				{thirdRow.map((color) => (
					<div key={color.name}>
						<ColorsContent color={color} />
					</div>
				))}
			</ColorSchemeThirdContainer>
		</>
	);
}

export default SectionColorScheme;

function ColorsContent({ color }) {
	return (
		<>
			<PrimaryColorBlock
				colorData={color}
				backgroundColor={color.primary}
				color={color.color}>
				<h4>{color.name.toUpperCase()}</h4>
				<PrimaryColorContent>
					<p>{color.primary.toUpperCase()}</p>
					<p>{color.rgba.toUpperCase()}</p>
				</PrimaryColorContent>
			</PrimaryColorBlock>
			{color.secondary ? (
				<SecondaryColorBlock
					backgroundColor={color.secondary}
					color={color.color}>
					{color.secondary.toUpperCase()}
				</SecondaryColorBlock>
			) : null}
		</>
	);
}
