import React, { useCallback, useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styled from "styled-components";
import { MediaQuery, Palette } from "../constants";
import skillsDataJson from "./data/HomeSkillsData.json";
import HomeSkillImages from "../assets/SVGs/HomeSkillImages";
import theme from "../theme";

const HomeSkills = () => {
	const [content, setContent] = useState(null);

	const getSkillsData = useCallback(
		() =>
			new Promise((resolve, reject) => {
				try {
					const data = JSON.parse(JSON.stringify(skillsDataJson));
					resolve(data);
				} catch (err) {
					reject(err);
				}
			}),
		[]
	);

	useEffect(() => {
		getSkillsData()
			.then((data) => {
				if (data) {
					setContent(data);
				}
			})
			.catch((err) => {
				// eslint-disable-next-line no-console
				console.error(err);
			});
	}, [getSkillsData]);

	useEffect(() => {
		if (content) {
			gsap.to(".home-skills-wrapper", {
				scrollTrigger: {
					trigger: ".home-skills-wrapper",
					start: "top 75%",
					toggleActions: "play none none none",
				},
				opacity: 1,
				transform: "translateY(0px)",
				duration: 1,
			});
			ScrollTrigger.refresh();
		}
		return () => {
			ScrollTrigger.getAll().forEach((trigger) => {
				trigger.kill();
			});
		};
	}, [content]);

	const getContent = () => {
		if (!content) {
			return null;
		}
		return (
			<HomeSkillsWrapper>
				<Container>
					<Title>{content.title}</Title>
					<Subtitle>{content.subtitle}</Subtitle>
					<SkillsElementContainer>
						{content.subContent.map((skill) => (
							<SkillElement key={skill.id}>
								<HomeSkillImages
									id={skill.id}
									color={Palette.BLACK}
								/>

								<SkillElementHeader>{skill.title}</SkillElementHeader>

								<SkillElementDescription>
									{skill.description}
								</SkillElementDescription>
							</SkillElement>
						))}
					</SkillsElementContainer>
				</Container>
			</HomeSkillsWrapper>
		);
	};

	return getContent();
};

const HomeSkillsWrapper = styled.div`
	background-color: ${Palette.WHITE};
	width: 100%;
	padding-top: 80px;
	padding-bottom: 60px;
`;

const Container = styled.div.attrs({
	className: "home-skills-wrapper",
})`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-left: 24px;
	padding-right: 24px;
	opacity: 0;
	transform: translateY(20px);
	@media ${MediaQuery.tablet} {
		flex-direction: row;
		margin: 0px;
		padding: 75px;
	}

	@media ${MediaQuery.desktopS} {
		margin: 0px auto;
		max-width: 1512px;
	}
`;

const Title = styled.p`
	font-size: 24px;
	margin: 0 auto;
	@media ${MediaQuery.tablet} {
		margin: 0;
		width: 40vw;
		${theme.textStyles.h4}
	}
`;

const Subtitle = styled.p`
	font-size: 12px;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 48px;
	margin-top: 12px;

	@media ${MediaQuery.tablet} {
		display: none;
		margin: 0;
	}
`;

const SkillsElementContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;

	@media ${MediaQuery.tablet} {
		flex: 1;
		gap: 0.8rem;
	}

	@media ${MediaQuery.desktopS} {
		gap: 1rem;
		flex: 1;
	}
`;

const SkillElement = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 48px;
	flex-basis: 100%;

	@media ${MediaQuery.tablet} {
		flex-basis: 46%;
	}

	@media ${MediaQuery.laptopM} {
		flex-basis: 40%;
	}
	@media ${MediaQuery.desktopM} {
		flex-basis: 36%;
		padding-left: 0rem;
		padding-right: 5rem;
	}
`;

const SkillElementHeader = styled.div.attrs({
	className: "skill-element-header",
})`
	font-size: 24px;
	padding: 0.5rem 0 0.5rem 0;
	color: black;
`;

const SkillElementDescription = styled.div.attrs({
	className: "skill-element-description",
})`
	color: black;
	font-size: 12px;
	@media ${MediaQuery.tablet} {
		font-size: 18px;
	}
`;
export default HomeSkills;
