import React from "react";
import styled from "styled-components";
import { MediaQuery } from "../../constants";

export default function HashLink({ data }) {
	const { number, title } = data;
	return (
		<Wrapper id={`${number} ${title.toLowerCase()}`}>
			<Title>{`${number} ${title}`}</Title>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	display: flex;
	margin: 35px 24px;
	max-width: 1512px;

	@media ${MediaQuery.tablet} {
		margin: 60px 80px;
	}
	@media ${MediaQuery.desktopM} {
		margin: 120px auto;
	}
`;

const Title = styled.h3`
	font-size: clamp(1.5rem, 3vw, 3rem);
	font-style: normal;
	font-weight: 400;
	line-height: 124%;
	margin: 0px;
	text-align: center;
	display: flex;
	align-items: center;
	margin-left: 1px;
	@media ${MediaQuery.desktopM} {
		font-size: clamp(2rem, 3vw, 3rem);
	}
`;
