import styled from "styled-components";
import { Palette, MediaQuery } from "../constants";

const SkillsContainer = styled.div.attrs({
	className: "skills-container",
})`
	background-color: ${Palette.BLACK};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0.5rem;
	padding-top: 3rem;

	@media ${MediaQuery.tablet} {
		padding: 0.5rem;
		padding-top: 3rem;
		padding-right: 0rem;
		padding-left: 4rem;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
	}
`;

const SkillsHeading = styled.div.attrs({
	className: "skills-heading",
})`
	color: ${Palette.WHITE};
	width: 100%;
	display: flex;
	flex-direction: column;
	@media ${MediaQuery.tablet} {
		width: 40%;
	}
	@media ${MediaQuery.laptopL} {
		padding-right: 5rem;
	}
	@media ${MediaQuery.desktopM} {
		padding-right: 15rem;
	}
`;

const SkillsHeadingText = styled.div.attrs({
	className: "skills-heading-text",
})`
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-left: 0;
	font-size: clamp(1.5rem, 3vw, 5rem);
	@media ${MediaQuery.tablet} {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
	}
`;

const SkillsHeadingDescription = styled.div.attrs({
	className: "skills-heading-description",
})`
	padding-top: 1rem;
	width: 90%;
	text-align: center;
	margin-right: 0;
	margin: auto;
	@media ${MediaQuery.tablet} {
		width: 80%;
		text-align: left;
		margin-left: 0;
	}
`;

const ResumeButton = styled.button.attrs({
	className: "resume-button",
})`
	background-color: ${Palette.WHITE};
	color: black;
	height: 3rem;
	font-size: 1.2rem;
	border: none;
	border-radius: 0.2rem;
	padding: 0 1rem 0 1rem;
	margin-top: 2rem;

	width: 60%;
	align-self: center;

	@media ${MediaQuery.tablet} {
		width: 12.5rem;
		align-self: flex-start;
	}
`;

const SkillsDisplay = styled.div.attrs({
	className: "skills-display",
})`
	color: ${Palette.WHITE};
	display: flex;
	flex-wrap: wrap;

	margin-top: 2rem;
	width: 90%;

	@media ${MediaQuery.tablet} {
		margin-top: 0rem;
		color: ${Palette.WHITE};
		display: flex;
		flex-wrap: wrap;
		width: 60%;
	}
`;

const SkillElement = styled.div.attrs({
	className: "skill-element",
})`
	display: flex;
	flex-direction: column;
	margin-bottom: 3rem;
	flex-basis: 100%;

	@media ${MediaQuery.laptopS} {
		flex-basis: 45%;
		padding-left: 1rem;
	}
	@media ${MediaQuery.desktopM} {
		padding-right: 0rem;
	}
`;

const SkillElementHeader = styled.div.attrs({
	className: "skill-element-header",
})`
	font-size: clamp(1rem, 20px, 1.3rem);

	padding: 0.5rem 0 0.5rem 0;
	color: ${(props) => props.textColor};

	@media ${MediaQuery.tablet} {
		font-size: 24px;
	}
`;

const SkillElementDescription = styled.div.attrs({
	className: "skill-element-description",
})`
	color: ${(props) => props.textColor};
	width: 95%;
	font-size: 12px;

	@media ${MediaQuery.tablet} {
		font-size: 18px;
	}
`;

export {
	SkillsContainer,
	SkillsHeading,
	SkillsDisplay,
	SkillElement,
	SkillsHeadingText,
	SkillsHeadingDescription,
	ResumeButton,
	SkillElementHeader,
	SkillElementDescription,
};
