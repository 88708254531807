const routePath = {
	DEFAULTPROJECT: "Work/Default-Project",
	ABOUT: "About",
	WORK: "Work",
	PRESS: "Press",
	HOME: "Home",
	ROOT: "/",
};

export default routePath;
