import React from "react";
import styled from "styled-components";
import { MediaQuery } from "../../constants";

export default function RoleDetails({ data, name }) {
	const { role, expertise, timeline, when } = data;

	return (
		<Container className={`${name}-project-role`}>
			<RoleSectionComponent
				title='role'
				text={role}
			/>
			<ExpertiesSectionComponent
				title='areas of expertise'
				text={expertise}
				width='90%'
			/>
			<GroupSectionContainer>
				<SectionComponent
					title='timeline'
					text={timeline}
					width='50%'
				/>
				<SectionComponent
					title='when'
					text={when}
					width='50%'
				/>
			</GroupSectionContainer>
		</Container>
	);
}
function SectionComponent({ title, text, width }) {
	return (
		<SectionContainer width={width}>
			<Title>{title}</Title>
			<Text>{text}</Text>
		</SectionContainer>
	);
}
function RoleSectionComponent({ title, text, width }) {
	return (
		<RoleContainer width={width}>
			<Title>{title}</Title>
			<Text>{text}</Text>
		</RoleContainer>
	);
}
function ExpertiesSectionComponent({ title, text, width }) {
	return (
		<ExpertiesContainer width={width}>
			<Title>{title}</Title>
			<Text>{text}</Text>
		</ExpertiesContainer>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: end;
	gap: 0px;
	width: 344px;
	height: auto;
	margin-left: 0;
	margin-top: 10px;
	opacity: 0;
	transform: translateY(50px);
	animation:
		fadeIn 0.75s ease-in 0.4s 1 normal forwards running,
		slideIn 0.5s ease-out 0.4s 1 normal forwards running;

	@media ${MediaQuery.tablet} {
		width: 394px;
		height: 300px;
		margin-left: 35px;
		margin-top: 0;
		margin-right: -20px;
		gap: 40px;
	}

	@media ${MediaQuery.laptopL} {
		margin-right: 0px;
	}
`;

const SectionContainer = styled.div`
	width: ${(props) => props.width || "100%"};
	margin-top: 14px;

	@media ${MediaQuery.tablet} {
		margin-top: 0;
	}
`;

const RoleContainer = styled.div`
	width: ${(props) => props.width || "100%"};
	margin-top: 14px;

	@media ${MediaQuery.tablet} {
		margin-top: 0;
	}
`;

const ExpertiesContainer = styled.div`
	width: ${(props) => props.width || "100%"};
	margin-top: 14px;

	@media ${MediaQuery.tablet} {
		margin-top: 0;
	}
`;

const GroupSectionContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

const Title = styled.div`
	font-size: clamp(0.75rem, 2vw, 1.125rem);
	font-weight: 600;
	line-height: 27px;
	text-transform: capitalize;
`;

const Text = styled.div`
	font-size: clamp(0.75rem, 2vw, 1.125rem);
	line-height: 150%;
`;
