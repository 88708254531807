import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useEffect } from "react";

import Home from "../../Home/Home";
import AboutMe from "../../AboutMe/AboutMe";
import { Work } from "../../Work";
import MainLayout from "../../Layout";
import CaseStudy from "../../CaseStudy";
import PressPage from "../../Press/Press";
import routePath from "./routePath";
import { useLoading } from "../../context/LoadingContext";
import SplashScreen from "../../SplashScreen/SplashScreen";
// import ProjectOverview from "../../Work/ProjectOverview.js";

function AppRouter() {
	const { loading, setLoading } = useLoading();

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 3000);
	}, []);

	const mainElement = loading ? <SplashScreen /> : <MainLayout />;

	const routes = createBrowserRouter([
		{
			path: routePath.ROOT,
			element: mainElement,
			children: [
				{
					element: <Home />,
					index: true,
				},
				{
					path: `${routePath.WORK}/:id`,
					element: <CaseStudy />,
				},
				{
					path: routePath.WORK,
					element: <Work />,
				},
				{
					path: routePath.ABOUT,
					element: <AboutMe />,
				},
				{
					path: routePath.PRESS,
					element: <PressPage />,
				},
			],
		},
	]);

	return <RouterProvider router={routes} />;
}

export default AppRouter;
