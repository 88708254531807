import { useState, useEffect } from "react";
import styled from "styled-components";
import { Outlet, useLocation } from "react-router-dom";
import { Palette } from "../constants";

import Nav from "../Nav/Nav";
import GlobalStyle from "../globalStyles";
import RoundMouse from "../assets/SVGs/RoundMouse";
import Footer from "../Footer/Footer";

const AppContainer = styled.div.attrs({
	className: "app-container",
})`
	margin: 0;
	background-color: ${(props) =>
		props.appColor === true ? `${Palette.BLACK}` : `${Palette.WHITE}`};
	overflow-x: hidden;
`;

export default function MainLayout() {
	const { pathname } = useLocation();
	const path = pathname ? pathname.slice(1) : "";
	const isBlackBg = path === "";
	const [mobileMenu, setMobileMenu] = useState(false);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	useEffect(() => {
		window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
	}, []);
	return (
		<AppContainer appColor={isBlackBg}>
			<GlobalStyle mobileMenu={mobileMenu} />
			<RoundMouse />
			<Nav
				isBlackBg={isBlackBg}
				mobileMenu={mobileMenu}
				setMobileMenu={setMobileMenu}
			/>

			<Outlet context={{ mobileMenu, setMobileMenu, screenWidth }} />
			<Footer />
		</AppContainer>
	);
}
