import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styled from "styled-components";
import IMAGES from "../../assets/images";
import { MediaQuery } from "../../constants";

gsap.registerPlugin(ScrollTrigger);
// disable the warning for null target when components are not mounted
gsap.config({
	nullTargetWarn: false,
});

function FloatingImage({
	subContent,
	index,
	relativeHeight,
	relativeMobileHeight,
	sectionId,
}) {
	useEffect(() => {
		// Need setTimeout to wait until loading bar completes. Otherwise the scroll trigger markers will be overriden by the loading bar
		setTimeout(() => {
			gsap.to(`.float-up-image-${sectionId}-${index}`, {
				y: -23,
				duration: 3,
				scrollTrigger: {
					trigger: `.float-image-container-${sectionId}`,
					start: "top 75%",
					end: "top 5%",
					scrub: 2,
					toggleActions: "restart pause reverse none",
				},
			});
			gsap.to(`.float-down-image-${sectionId}-${index}`, {
				y: 23,
				duration: 3,
				yoyo: true,
				scrollTrigger: {
					trigger: `.float-image-container-${sectionId}`,
					start: "top 75%",
					end: "top 5%",
					scrub: 2,
					toggleActions: "restart pause reverse none",
				},
			});
		}, 200);

		ScrollTrigger.refresh();
		// Need to kill all the scroll triggers when page unmounts

		return () => {
			ScrollTrigger.getAll().forEach((trigger) => {
				trigger.kill();
			});
		};
	}, []);

	return (
		<>
			<ImageWrapper
				className={
					index % 2 === 0
						? `float-up-image-${sectionId}-${index}`
						: `float-down-image-${sectionId}-${index}`
				}
				index={index}
				jsonStyles={subContent.styles}
				relativeHeight={relativeHeight}>
				<Image
					subContent={subContent}
					src={subContent.image}
				/>
			</ImageWrapper>
			{subContent.mobileImage ? (
				<MobileImageWrapper
					jsonStyles={subContent.styles}
					relativeMobileHeight={relativeMobileHeight}>
					<Image
						subContent={subContent}
						src={subContent.mobileImage}
					/>
				</MobileImageWrapper>
			) : null}
		</>
	);
}

// calculate the relative ratio of width, height and postion also the ratio of mobile width, height and position
const ImageWrapper = styled.div`
	position: absolute;
	display: none;

	@media ${MediaQuery.tablet} {
		display: block;
		top: ${({ jsonStyles, relativeHeight }) =>
			`${(jsonStyles.top * 100) / relativeHeight}%` || "0"};
		left: ${({ jsonStyles }) => `${(jsonStyles.left * 100) / 1512}%` || "0"};
		width: ${({ jsonStyles }) => `${(jsonStyles.width * 100) / 1512}%` || "0"};
		height: ${({ jsonStyles, relativeHeight }) =>
			`${(jsonStyles.height * 100) / relativeHeight}%` || "0"};
	}
`;

const MobileImageWrapper = styled.div`
	position: absolute;
	top: ${({ jsonStyles, relativeMobileHeight }) =>
		`${(jsonStyles.mobileTop * 100) / relativeMobileHeight}%` || "0"};
	left: ${({ jsonStyles }) => `${(jsonStyles.mobileLeft * 100) / 390}%` || "0"};
	width: ${({ jsonStyles }) =>
		`${(jsonStyles.mobileWidth * 100) / 390}%` || "0"};
	height: ${({ jsonStyles, relativeMobileHeight }) =>
		`${(jsonStyles.mobileHeight * 100) / relativeMobileHeight}%` || "0"};
	@media ${MediaQuery.tablet} {
		display: none;
	}
`;

const Image = styled.img.attrs(({ subContent, src }) => ({
	src: IMAGES[src],
	alt: subContent.image || "Image Alt Text",
}))`
	width: 100%;
	height: 100%;
`;

export default FloatingImage;
