import styled from "styled-components";
import { ProjectDetails, RoleDetails } from "../components";
import IMAGES from "../../assets/images";
import { MediaQuery } from "../../constants";
import HashLinks from "../components/HashLinks";

export default function Hero({ data }) {
	const { project, role, sections } = data;

	let { name } = project;
	[name] = name.split(" ");

	const getImageSrc = () => {
		if (window.matchMedia(`(${MediaQuery.tablet})`).matches) {
			return IMAGES[`${project.name}_hero`];
		}
		return (
			IMAGES[`${project.name}_hero_mobile`] || IMAGES[`${project.name}_hero`]
		);
	};

	const getComponents = () => (
		<>
			<DetailsContainer
				role={role}
				sections={sections}>
				<ProjectDetails
					data={project}
					style={{ width: !role ? "100%" : undefined }}
				/>
				{role && (
					<RoleDetails
						data={role}
						name={name}
					/>
				)}
			</DetailsContainer>
			{sections[0].type === "hash_links" && (
				<HashLinks
					key={`section-${sections[0].type}`}
					data={sections[0]}
				/>
			)}
			<Image
				name={name}
				src={getImageSrc()}
				alt={`${name} project images for hero section`}
			/>
		</>
	);

	return <Container modifier={null}>{getComponents()}</Container>;
}

const Container = styled.div`
	@media ${MediaQuery.laptopL} {
		max-width: 1512px;
		margin: auto;
	}
`;

const DetailsContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	margin: ${(props) =>
		props.role === undefined ? "0 24px 0px" : "0 24px 48px"};
	@media ${MediaQuery.tablet} {
		flex-direction: row;
		align-items: flex-end;
		margin: ${(props) =>
			props.role === undefined ? "0 auto 0px" : "48px 80px "};
	}

	@media ${MediaQuery.desktopS} {
		margin: ${(props) =>
			props.role === undefined ? "0 auto 0px" : "0 auto 96px"};
	}
`;

const Image = styled.img`
	z-index: 1;
	width: 100%;
	height: auto;
	opacity: 0;

	animation:
		fadeIn 0.75s ease-in 0.8s 1 normal forwards running,
		slideIn 0.5s ease-out 0.8s 1 normal forwards running;
`;
