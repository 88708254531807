import { gsap } from "gsap";

export function onMouseHover() {
	gsap.to(".round-mouse-svg", { scale: 4, duration: 0.3 });
	gsap.to(".circle", {
		fill: "#D9D9D9",
		duration: 0.3,
	});
}

export function onMouseHoverOut() {
	gsap.to(".round-mouse-svg", { scale: 1, duration: 0.3 });
	gsap.to(".circle", {
		fill: "#EAC4FC",
		duration: 0.3,
	});
}

export function onMouseHoverPress() {
	gsap.to([".round-mouse-svg"], {
		scale: 5,
		duration: 0.3,
	});

	gsap.to(".top-right-arrow", {
		translateX: "-20px",
		scale: 4,
		display: "block",
		duration: 0.1,
	});

	gsap.to(".circle", {
		fill: "#fff",
	});
}

export function onMouseLeavePress() {
	gsap.to([".round-mouse-svg"], {
		scale: 1,
		duration: 0.3,
	});

	gsap.to(".top-right-arrow", {
		display: "none",
		scale: 0,
		translateX: "0px",
		duration: 0.1,
	});

	gsap.to(".circle", {
		fill: "#EAC4FC",
	});
}

export function onMouseHoverOverCard() {
	gsap.to(".round-mouse-svg", {
		scale: 4,
		duration: 0.3,
	});

	gsap.to(".circle", {
		fill: "#000000",
		duration: 0.3,
	});

	gsap.to(".cursor-text", {
		opacity: 1,
		duration: 0.1,
	});
	gsap.to(".round-mouse", {
		mixBlendMode: "normal",
		duration: 0.3,
	});
}

export function onMouseHoverOutCard() {
	gsap.to(".round-mouse-svg", { scale: 1, duration: 0.3 });

	gsap.to(".circle", {
		fill: "#EAC4FC",
		duration: 0.3,
	});

	gsap.to(".cursor-text", {
		opacity: 0,
		duration: 0.1,
	});

	gsap.to(".round-mouse", {
		mixBlendMode: "difference",
		duration: 0.3,
	});
}

const originalMousePosition = { x: 0, y: 0 };

let previousScrollY = 0;

// export function handleMouseMove(e) {
// 	// originalMousePosition.x = e.pageX - 15;
// 	// originalMousePosition.y = e.pageY - 15;
// 	const { clientX, clientY } = e;
// 	// originalMousePosition.x = clientX - 15;
// 	// originalMousePosition.y = clientY - 15;

// 	const x = Math.round((clientX - 15 / window.innerWidth) * 100);
// 	const y = Math.round((clientY - 15 / window.innerHeight) * 100);

// 	console.log(clientX, clientY, x, y);

// 	gsap.to(".round-mouse", {
// 		"--x": `${x}%`,
// 		"--y": `${y}%`,
// 		duration: 0.4,
// 		ease: "sine.out",
// 	});
// }

export function handleMouseMove(e) {
	const { clientX, clientY } = e;
	gsap.to(".round-mouse", { duration: 0.4, x: clientX - 15, y: clientY - 15 });
}

export function handleScroll() {
	const scrollDirection = previousScrollY > window.scrollY ? "up" : "down";

	const newX = originalMousePosition.x + window.scrollX;
	let newY;

	if (scrollDirection === "down") {
		newY = originalMousePosition.y + window.scrollY - previousScrollY;
	} else {
		newY = originalMousePosition.y - (previousScrollY - window.scrollY);
	}

	originalMousePosition.x = newX;
	originalMousePosition.y = newY;

	previousScrollY = window.scrollY;

	gsap.to(".round-mouse", {
		x: newX,
		y: newY,
		duration: 0,
		smoothOrigin: true,
	});
}
