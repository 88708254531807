import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styled from "styled-components";
import IMAGES from "../../assets/images";
import AboutMeSkillsElement from "../../AboutMe/AboutMeSkillsElement";
import { MediaQuery } from "../../constants";
import theme from "../../theme";

export default function HorizontalCard({ data }) {
	const { name, description, image, underLine, subContent, id } = data;

	useEffect(() => {
		setTimeout(() => {
			gsap.to(
				[
					`.section-title-${id}`,
					`.section-skill-container-${id}`,
					`.section-description-container-${id}`,
				],
				{
					opacity: 1,
					transform: "translateY(0px)",
					duration: 1,
					scrollTrigger: {
						trigger: `.section-title-${id}`,
						start: "top 75%",
						toggleActions: "play none none none",
					},
				}
			);
			ScrollTrigger.refresh();
		}, 300);

		return () => {
			ScrollTrigger.getAll().forEach((trigger) => {
				trigger.kill();
			});
		};
	}, []);

	const generateDescription = (rawText) => {
		const splittedText = rawText.split("\n");

		return (
			<>
				{splittedText.map((text, index) => (
					<Description
						key={`${id}_${name}_${text.substring(0, 15)}`}
						isLastItem={splittedText.length - 1 === index}>
						{/* eslint-disable-next-line react/no-danger */}
						<span dangerouslySetInnerHTML={{ __html: text }} />
					</Description>
				))}
			</>
		);
	};

	const getContent = (imgeName, rawText, subContentData) => {
		if (subContent) {
			return (
				<SkillsElementContainer className={`section-skill-container-${id}`}>
					{subContentData.map((content) => (
						<AboutMeSkillsElement
							key={content.title}
							title={content.title}
							description={content.description}
						/>
					))}
				</SkillsElementContainer>
			);
		}
		return (
			<GroupingContainer className={`section-description-container-${id}`}>
				{generateDescription(rawText)}
				{imgeName && (
					<Image
						src={IMAGES[imgeName]}
						alt='manzil branding images'
					/>
				)}
			</GroupingContainer>
		);
	};

	return (
		<>
			<Container
				description={description}
				subContent={subContent}>
				<Title id={id}>{name}</Title>
				{description || subContent?.length > 0
					? getContent(image, description, subContent)
					: null}
			</Container>
			{underLine && <HorizontalLine />}
		</>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: ${({ description, subContent }) =>
		description || subContent?.length > 0 ? "50px 24px" : "50px 24px 0px"};

	@media ${MediaQuery.tablet} {
		max-width: 1512px;
		flex-direction: row;
		margin: ${({ description, subContent }) =>
			description || subContent?.length > 0 ? "80px 45px" : "80px 45px 0px"};
	}
	@media ${MediaQuery.desktopS} {
		margin: ${({ description, subContent }) =>
			description || subContent?.length > 0 ? "80px auto" : "80px auto 0px"};
	}

	@media ${MediaQuery.desktopM} {
		max-width: 1512px;
		padding: 0 10px;
	}
`;

const GroupingContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 344px;
	opacity: 0;
	transform: translateY(20px);

	@media ${MediaQuery.tablet} {
		width: 663px;
	}

	@media ${MediaQuery.desktopM} {
		width: 768px;
	}
`;

const SkillsElementContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 344px;
	opacity: 0;
	transform: translateY(20px);

	@media ${MediaQuery.tablet} {
		width: 663px;
	}
	@media ${MediaQuery.desktopM} {
		width: 768px;
	}
`;

const HorizontalLine = styled.hr.attrs({
	className: "hr-line",
})`
	height: 1px;
	background-color: rgba(0, 0, 0, 0.2);
	border: none;

	margin: 0 24px;

	@media ${MediaQuery.tablet} {
		margin: 0 80px;
	}
`;

const Title = styled.div.attrs(({ id }) => ({
	className: `section-title-${id}`,
}))`
	${theme.textStyles.h4}
	text-transform: capitalize;
	min-width: 230px;
	margin-bottom: 10px;
	opacity: 0;
	transform: translateY(20px);

	@media ${MediaQuery.tablet} {
		margin-bottom: 0;
	}
`;

const Description = styled.div`
	font-size: clamp(0.75rem, 2vw, 1.125rem);
	line-height: 150%;
	${({ isLastItem }) => !isLastItem && "margin-bottom: 20px;"}
`;

const Image = styled.img`
	z-index: 1;
	width: 100%;
	height: auto;
	margin-top: 40px;

	@media ${MediaQuery.tablet} {
		margin-top: 60px;
	}
`;
