import React, { useEffect } from "react";
import styled from "styled-components";

import { handleMouseMove } from "../../Utils/mouseEvents";
import TopRightArrow from "./TopRightArrow";

export default function RoundMouse() {
	useEffect(() => {
		document.addEventListener("mousemove", handleMouseMove);

		return () => {
			document.removeEventListener("mousemove", handleMouseMove);
		};
	}, []);

	return (
		<Cursor className='round-mouse'>
			<CursorBall className='cursor__ball cursor__ball--big '>
				<svg
					className='round-mouse-svg'
					height='30'
					width='30'>
					<Circle
						className='circle'
						cx='15'
						cy='15'
						r='12'
						stroke-width='0'
						style={{ fill: "#EAC4FC" }}
						fill='#EAC4FC'
					/>
				</svg>
				<TopRightArrow style={{ display: "none" }} />
				<CursorText>Case Study</CursorText>
			</CursorBall>
		</Cursor>
	);
}

const Cursor = styled.div`
	/* pointer-events: none; */
	mix-blend-mode: difference;
	max-width: 100px; // width for the actual mouse so that it doesn't overflow the page
	z-index: 1000;
	position: fixed;
	opacity: 1;
	overflow: visible;
	@media (max-width: 756px) {
		pointer-events: auto;
		display: none;
	}
`;

const CursorBall = styled.div`
	pointer-events: none;
	position: absolute;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Circle = styled.circle`
	fill: "#EAC4FC";
`;

const CursorText = styled.p.attrs({
	className: "cursor-text",
})`
	font-size: 1rem;
	/* z-index: 9; */
	position: absolute;
	top: -1.7rem;
	opacity: 0;
	color: white;
`;
