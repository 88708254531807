import React from "react";
import AboutMeHero from "./AboutMeHero";
import AboutMeSkills from "./AboutMeSkills";
import AboutMeAwards from "./AboutMeAwards";

export default function AboutMe({ screenWidth, mobileMenu }) {
	return (
		<>
			<AboutMeHero
				screenWidth={screenWidth}
				mobileMenu={mobileMenu}
			/>
			<AboutMeSkills screenWidth={screenWidth} />
			<AboutMeAwards screenWidth={screenWidth} />
		</>
	);
}
