import styled from "styled-components";
import { Palette, MediaQuery } from "../constants";

const HomeHeroWrapper = styled.div.attrs({
	className: "home-hero-wrapper",
})`
	font-size: 20rem;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 94px;

	@media ${MediaQuery.mobileL} {
		margin-top: 2rem;
	}

	@media ${MediaQuery.tablet} {
		height: 89vh;
		margin-top: 0;
	}
`;

const HomeHeroHeader = styled.header.attrs({
	className: "home-hero-header",
})`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 24px;
	margin-top: 48px;
	width: 90%;
	gap: 24px;

	@media ${MediaQuery.tablet} {
		margin: auto;
		padding-left: 5%;
		padding-top: 50px;
		width: 80%;
		max-width: 1512px;
		gap: 48px;
	}
`;

const HomeHeroName = styled.h2.attrs({
	className: "home-hero-name",
})`
	font-size: clamp(1.3rem, 2vw, 2rem);
	font-weight: 400;
`;

const HeroTitleContainer = styled.div.attrs({
	className: "header-nav-container",
})`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	position: static;
	z-index: 0;
`;

const HeroTitleElement = styled.div.attrs({
	className: "hero-title-element",
})`
	display: flex;
	align-items: center;
	font-family: Frank Ruhl Libre;
	font-size: clamp(1.5rem, 8vw, 3rem);
	text-transform: uppercase;
	transition: all 0.3s ease-in-out;
	color: ${(props) =>
		props.highlight === true ? `${Palette.BLACK}` : props.fontColor};

	@media ${MediaQuery.tablet} {
		font-size: clamp(1rem, 5vw, 8rem);
	}
`;

const SecondHeroTitleElement = styled(HeroTitleElement).attrs({
	className: "hero-title-elememt-2",
})`
	margin-left: 10%;
	@media ${MediaQuery.tablet} {
		margin-left: 15%;
	}
`;

const ThirdHeroTitleElement = styled(HeroTitleElement).attrs({
	className: "hero-title-elememt-3",
})`
	margin-left: 0%;
	@media ${MediaQuery.tablet} {
		margin-left: 10%;
	}
`;

const FourthHeroTitleElement = styled(HeroTitleElement).attrs({
	className: "hero-title-elememt-4",
})`
	margin-left: 10%;
	@media ${MediaQuery.tablet} {
		margin-left: -7.5%;
	}
`;

const HoverBox = styled.div.attrs({
	className: "hover-box",
})`
	position: absolute;
	${(props) => props.position === "elem1" && "left: 60%;"}
	${(props) => props.position === "elem2" && "right: 85%;"}
  ${(props) => props.position === "elem3" && "left: 72%;"}
  ${(props) => props.position === "elem4" && "left: 35%; margin-bottom: 40%;"}
  ${(props) => props.size}
  z-index: 200;
	background-color: ${Palette.GREY_SOFT};
	box-shadow: ${(props) => props.boxShadow};
	transition: all 0.3s ease-in-out;
	${(props) =>
		props.animation === "default"
			? "animation: fade-grow-in 1s ease-in-out forwards;"
			: props.animation};

	@media ${MediaQuery.tablet} {
		${(props) => props.position === "elem1" && "left: 50%;"}
		${(props) => props.position === "elem2" && "right: 75%;"};
		${(props) => props.position === "elem3" && "left: 65%;"}
		${(props) => props.position === "elem4" && "margin-bottom: 22%;"}
	}
	@media ${MediaQuery.desktopL} {
		${(props) => props.position === "elem4" && "margin-bottom: 17%;"}
	}
`;

const HeroFooterContainer = styled.div.attrs({
	className: "hero-footer-container",
})`
	position: static;
	z-index: 0;
	display: flex;
	width: 90%;
	justify-content: flex-end;
	gap: 48px;
	flex-direction: column-reverse;
	align-self: center;
	align-items: flex-end;

	opacity: 0;
	transform: translateY(50px);

	animation:
		fadeIn 0.75s ease-in 0.4s 1 normal forwards running,
		slideIn 0.5s ease-out 0.4s 1 normal forwards running;

	@media ${MediaQuery.tablet} {
		gap: 0;
		flex-direction: row;
		align-items: center;
	}
`;

const ArrowCircle = styled.div.attrs({
	className: "arrow-circle",
})`
	width: 76px;
	height: 76px;
	border: 2px solid ${Palette.WHITE};
	border-radius: 50%;
	margin-right: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
	align-self: center;
	@media ${MediaQuery.mobileL} {
		font-size: 2.5rem;
		width: 4.5rem;
		height: 4.5rem;
	}

	@media ${MediaQuery.laptopS} {
		margin-right: 3rem;
	}

	@media ${MediaQuery.laptopL} {
		font-size: 3rem;
		width: 120px;
		height: 120px;
		margin-right: 4rem;
		font-size: 4rem;
	}
`;

const TextBox = styled.div.attrs({
	className: "hero-text-box",
})`
	width: 67%;
	color: ${Palette.WHITE};
	font-size: 12px;
	line-height: 1.2rem;

	@media ${MediaQuery.tablet} {
		width: 50%;
	}

	@media ${MediaQuery.laptopS} {
		font-size: 1rem;
		line-height: 1.3rem;
		margin-bottom: 0;
	}
	@media ${MediaQuery.laptopL} {
		width: 45%;
		font-size: 1.5rem;
		line-height: 1.8rem;
	}
`;

const GrowWrapper = styled.div.attrs({
	className: "grow-wrapper",
})`
	/* animation-delay: 0.5s; */
	width: 100%;
	line-height: 124%;
	font-size: 40px;
	align-self: center;
	/* transition: all 0.3s ease-in-out; */
	color: ${Palette.WHITE};
	opacity: 0;
	transform: translateY(50px);
	animation:
		fadeIn 0.75s ease-in 0s 1 normal forwards running,
		slideIn 0.5s ease-out 0s 1 normal forwards running;

	@media ${MediaQuery.mobileL} {
		font-size: 41px;
		width: 95%;
	}
	@media ${MediaQuery.tablet} {
		font-size: 65px;
		animation-delay: 0s;
	}

	@media ${MediaQuery.laptopS} {
		width: 90%;
		font-size: 73px;
		line-height: 90px;
		margin-bottom: 3rem;
	}
	@media ${MediaQuery.laptopM} {
		width: 85%;
	}

	@media ${MediaQuery.laptopL} {
		width: 80%;
	}

	@media ${MediaQuery.laptopL} {
		font-size: 105px;
		line-height: 127px;
	}
`;

export {
	HomeHeroWrapper,
	HomeHeroHeader,
	HomeHeroName,
	HeroTitleContainer,
	HeroTitleElement,
	SecondHeroTitleElement,
	ThirdHeroTitleElement,
	FourthHeroTitleElement,
	HoverBox,
	HeroFooterContainer,
	ArrowCircle,
	TextBox,
	GrowWrapper,
};
