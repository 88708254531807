import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { MediaQuery, Palette } from "../constants";
import skillsDataJson from "../Home/data/HomeSkillsData.json";
import HomeSkillImages from "../assets/SVGs/HomeSkillImages";

const AboutMeSkills = () => {
	const [content, setContent] = useState(null);

	const getSkillsData = useCallback(
		() =>
			new Promise((resolve, reject) => {
				try {
					const data = JSON.parse(JSON.stringify(skillsDataJson));
					resolve(data);
				} catch (err) {
					reject(err);
				}
			}),
		[]
	);

	useEffect(() => {
		getSkillsData()
			.then((data) => {
				if (data) {
					setContent(data);
				}
			})
			.catch((err) => {
				// eslint-disable-next-line no-console
				console.error(err);
			});
	}, [getSkillsData]);

	const getContent = () => {
		if (!content) {
			return null;
		}
		return (
			<Container>
				<TitleWrapper>
					<Title>Skills & Experience</Title>
					<Subtitle>{content.subtitle}</Subtitle>
					<ResumeButton>View Resume</ResumeButton>
				</TitleWrapper>
				<SkillsElementContainer>
					{content.subContent.map((skill) => (
						<SkillElement key={`skill_${skill.id}`}>
							<HomeSkillImages
								id={skill.id}
								color={Palette.WHITE}
							/>

							<SkillElementHeader>{skill.title}</SkillElementHeader>

							<SkillElementDescription>
								{skill.description}
							</SkillElementDescription>
						</SkillElement>
					))}
				</SkillsElementContainer>
			</Container>
		);
	};

	return getContent();
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 30px;
	background-color: black;
	max-width: 1512px;

	@media ${MediaQuery.tablet} {
		flex-direction: row;
		margin: 0px;
		padding: 80px;
	}
	@media ${MediaQuery.laptopM} {
		margin: auto;
	}
`;

const TitleWrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: black;

	@media ${MediaQuery.tablet} {
		padding: 24px 0 0 24px;
		width: 30%;
	}
`;

const Title = styled.p`
	font-size: 30px;
	margin: 40px auto 0;
	color: white;

	@media ${MediaQuery.tablet} {
		font-size: 55px;
		margin: 0;
	}
`;

const Subtitle = styled.p`
	font-size: 16px;
	text-align: center;
	margin: 12px 0;
	color: white;

	@media ${MediaQuery.tablet} {
		text-align: start;
	}
`;

const ResumeButton = styled.button.attrs({
	className: "resume-button",
})`
	background-color: ${Palette.WHITE};
	color: black;
	height: 3rem;
	font-size: 1.2rem;
	border: none;
	border-radius: 0.2rem;
	padding: 0 1rem 0 1rem;
	margin-top: 1rem;
	width: 60%;
	align-self: center;
	margin-bottom: 70px;

	@media ${MediaQuery.tablet} {
		width: 12.5rem;
		align-self: flex-start;
	}
`;

const SkillsElementContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	@media ${MediaQuery.tablet} {
		width: 780px;
	}
	@media ${MediaQuery.laptopM} {
		width: 850px;
	}
`;

const SkillElement = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 3rem;
	flex-basis: 100%;

	@media ${MediaQuery.tablet} {
		flex-basis: 50%;
	}

	@media ${MediaQuery.laptopM} {
		flex-basis: 40%;
		padding-left: 3rem;
	}
	@media ${MediaQuery.desktopM} {
		padding-left: 0rem;
		padding-right: 5rem;
	}
`;

const SkillElementHeader = styled.div.attrs({
	className: "skill-element-header",
})`
	font-size: clamp(1.4rem, 1.5vw, 2rem);
	padding: 0.5rem 0 0.5rem 0;
	color: white;
`;

const SkillElementDescription = styled.div.attrs({
	className: "skill-element-description",
})`
	color: white;
`;
export default AboutMeSkills;
