import React from "react";
import { useNavigate } from "react-router-dom";
import {
  WorkImageWrapper,
  WorkImageColumnContainerMed,
  WorkImageColumnContainerSm,
  WorkImageColumnContainerLrg,
  WorkCube,
  WorkLandscapeMedium,
  WorkLandscapeSmall,
  WorkVerticalTall,
  WorkLandscapeLarge,
  LinkTitle,
  WorkImageColumnContainerXLrg
} from "./WorkElements";
import { CaseStudyIds } from "../constants";

export default function WorkImageBlock() {
  const navigate = useNavigate();

  return (
    <WorkImageWrapper>
      <WorkImageColumnContainerMed>
        <WorkCube onClick={() => navigate(CaseStudyIds.AUGGY)}>
            <LinkTitle>{CaseStudyIds.AUGGY}</LinkTitle>
        </WorkCube>
        <WorkLandscapeMedium onClick={() => navigate(CaseStudyIds.MANZIL)}>
            <LinkTitle>{CaseStudyIds.MANZIL}</LinkTitle>
        </WorkLandscapeMedium>
      </WorkImageColumnContainerMed>
      <WorkImageColumnContainerSm>
        <WorkLandscapeSmall onClick={() => navigate(CaseStudyIds.GRATA)}>
            <LinkTitle>{CaseStudyIds.GRATA}</LinkTitle>
        </WorkLandscapeSmall>
        <WorkVerticalTall onClick={() => navigate(CaseStudyIds.POPPY)}>
            <LinkTitle>{CaseStudyIds.POPPY}</LinkTitle>
        </WorkVerticalTall>
      </WorkImageColumnContainerSm>
      <WorkImageColumnContainerLrg>
        <WorkLandscapeLarge onClick={() => navigate(CaseStudyIds.NETCOINS)}>
            <LinkTitle>{CaseStudyIds.NETCOINS}</LinkTitle>
        </WorkLandscapeLarge>
        <WorkLandscapeLarge onClick={() => navigate(CaseStudyIds.DEFIENT)}>
            <LinkTitle>{CaseStudyIds.DEFIENT}</LinkTitle>
        </WorkLandscapeLarge>
      </WorkImageColumnContainerLrg>
      <WorkImageColumnContainerXLrg>
        <WorkLandscapeLarge onClick={() => navigate(CaseStudyIds.GRAPHICDESIGN)}>
            <LinkTitle>{CaseStudyIds.GRAPHICDESIGN}</LinkTitle>
        </WorkLandscapeLarge>
      </WorkImageColumnContainerXLrg>
    </WorkImageWrapper>
  );
}

