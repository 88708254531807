import React from "react";
import { BsBoxArrowUpRight, BsCheckSquare } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import {
	SkillElement,
	SkillElementHeader,
	SkillElementDescription,
} from "./SkillsElements";
import scalingIcon from "./Images/scaling.png";

const defaultTitle = "UX Design";
const defaultDescription =
	"I have been fortunate to have been recognized through awards and patents for my work in the technology and digital media sectors.";
const defaultTextColor = "black";

const getIcon = (path, textColor) => {
	switch (path) {
		case "Work/grata":
			return (
				<img
					src={scalingIcon}
					alt='scaling icon'
					width='24px'
					height='24px'
				/>
			);
		case "Work/netcoins":
			return <BsCheckSquare style={{ color: textColor }} />;
		default:
			return <BsBoxArrowUpRight style={{ color: textColor }} />;
	}
};

export default function AboutMeSkillsElement({
	title = defaultTitle,
	description = defaultDescription,
	textColor = defaultTextColor,
}) {
	const { pathname } = useLocation();
	const path = pathname ? pathname.slice(1) : "";

	return (
		<SkillElement>
			{getIcon(path, textColor)}
			<SkillElementHeader textColor={textColor}>{title}</SkillElementHeader>

			<SkillElementDescription textColor={textColor}>
				{description}
			</SkillElementDescription>
		</SkillElement>
	);
}
