import styled from "styled-components";
import { Palette, MediaQuery } from "../constants";

const AwardsContainer = styled.div.attrs({
	className: "awards-container",
})`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0.5rem;
	padding-top: 3rem;
	max-width: 1512px;
	margin: auto;
	@media ${MediaQuery.tablet} {
		padding: 0.5rem;
		padding-top: 3rem;
		padding-right: 0rem;
		padding-left: 4rem;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
	}
`;

const AwardsHeading = styled.div.attrs({
	className: "awards-heading",
})`
	color: ${Palette.WHITE};
	width: 100%;
	display: flex;
	flex-direction: column;

	@media ${MediaQuery.tablet} {
		width: 40%;
	}
	@media ${MediaQuery.laptopL} {
		padding-right: 5rem;
	}
	@media ${MediaQuery.desktopM} {
		padding-right: 15rem;
	}
`;

const AwardsHeadingText = styled.div.attrs({
	className: "awards-heading-text",
})`
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-left: 0;
	font-size: clamp(1.5rem, 3vw, 5rem);
	@media ${MediaQuery.tablet} {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		text-align: left;
	}
`;

const AwardsHeadingDescription = styled.div.attrs({
	className: "awards-heading-description",
})`
	padding-top: 1rem;
	width: 90%;
	text-align: center;
	margin-right: 0;
	margin: auto;
	@media ${MediaQuery.tablet} {
		width: 80%;
		text-align: left;
		margin-left: 0;
	}
`;

const AwardsDisplay = styled.div.attrs({
	className: "awards-display",
})`
	color: ${Palette.WHITE};
	margin-top: 2rem;
	width: 90%;
	@media ${MediaQuery.tablet} {
		margin-top: 0rem;
		width: 60%;
	}
`;

const AwardContainer = styled.div.attrs({
	className: "award-container",
})`
	color: ${Palette.WHITE};
	display: flex;
	flex-direction: column;
	border-top: 1px solid ${Palette.WHITE};
	padding-top: 1.5rem;
	padding-right: 0.5rem;
	margin: 0;
	padding-bottom: 3rem;

	@media ${MediaQuery.tablet} {
		padding-top: 1.5rem;
		margin-right: 5rem;
		padding-bottom: 5rem;
	}

	@media ${MediaQuery.laptopS} {
		margin-left: 6rem;
	}
`;

const AwardInfo = styled.div.attrs({
	className: "award-info",
})`
	display: flex;
	position: static;
	z-index: 100;
	font-size: 0.8rem;

	@media ${MediaQuery.tablet} {
		font-size: 1rem;
	}
`;

const AwardText = styled.div.attrs({
	className: "award-text",
})`
	display: flex;
	flex-direction: column;
	margin-left: 15%;
`;

const AwardName = styled.div.attrs({
	className: "award-name",
})`
	font-size: clamp(1.5rem, 3vw, 5rem);
`;

const AwardBox = styled.div.attrs((props) => ({
	className: "award-box",
	style: {
		backgroundColor: props.color,
	},
}))`
	position: absolute;
	z-index: 0;
	${(props) =>
		props.shape === "cube" &&
		"width: clamp(20rem, 27vw, 30rem); height: clamp(20rem, 27vw, 30rem);"}
	${(props) =>
		props.shape === "landscape" &&
		"width: clamp(28rem, 30vw, 38rem); height: clamp(13rem, 16vw, 17rem);"}
  ${(props) =>
		props.shape === "vertical" &&
		"width: clamp(15rem, 18vw, 22rem); height: clamp(25rem, 27vw, 30rem);"}
  transform: scale(0.6);
	display: flex;
	border-radius: 3%;
	animation: fade-grow-in-about 0.6s ease-in-out forwards;
	${(props) =>
		props.location === "lowerLeft" &&
		"margin-left: -4.375rem; margin-top: clamp(1rem, 3vw, 5rem);"}

	${(props) =>
		props.location === "lowerRight" && "margin-left: 0; margin-top: -5rem;"}

  ${(props) =>
		props.location === "upperLeft" &&
		"margin-left: -6.25rem; margin-top: clamp(-6rem, -6vw, -4rem);"}

  ${(props) =>
		props.location === "lowerLeftCube" &&
		"margin-left: -3rem; margin-top: -4rem;"}
  ${(props) =>
		props.location === "lowerRightLong" &&
		"margin-left: -5rem; margin-top: clamp(1rem, 5vw, 6rem);"}

    ${(props) =>
		props.location === "centerLeft" &&
		"margin-left: clamp(-2rem, -4vw, -4rem); margin-top: -7rem;"}

    ${(props) =>
		props.location === "centerLeftVertical" &&
		"margin-left: -3rem; margin-top: -13rem;"}


  @media ${MediaQuery.tablet} {
		${(props) =>
			props.location === "lowerLeft" &&
			"margin-left: clamp(-15rem, -20vw, -22rem);"}
		${(props) =>
			props.location === "lowerRight" &&
			"margin-left: clamp(5rem, 20vw, 30rem);"}
    ${(props) =>
			props.location === "upperLeft" &&
			"margin-left: clamp(-15rem, -20vw, -22rem);"}
    ${(props) =>
			props.location === "lowerLeftCube" &&
			" margin-left: clamp(-3rem, -6vw, -6rem); margin-top: clamp(-2rem, -3vw, -4rem);"}
      ${(props) => props.location === "lowerRightLong" && "margin-left: 2rem"}
      ${(props) =>
			props.location === "centerLeftVertical" && "margin-top: -9rem;"}
	}

	@media ${MediaQuery.laptopM} {
		${(props) =>
			props.location === "lowerRightLong" &&
			"margin-left: clamp(5rem, 20vw, 30rem);"}
	}

	@media ${MediaQuery.desktopS} {
		${(props) => props.location === "centerLeft" && "margin-top: -9rem;"}
	}

	@media ${MediaQuery.desktopL} {
		${(props) =>
			props.location === "lowerRight" &&
			"margin-left: clamp(5rem, 25vw, 40rem);"}
		${(props) =>
			props.location === "lowerRightLong" &&
			"margin-left: 40rem; margin-top: 3rem;"}
	}
`;

const AwardImage = styled.div.attrs(() => ({
	className: "award-image",
}))`
	${(props) => props.shape === "landscape" && "width: 17rem; height: 7rem;"}
	${(props) => props.shape === "cube" && "width: 12rem; height: 12rem;"}
  ${(props) => props.shape === "vertical" && "width: 14rem; height: 14rem;"}
  margin: auto;
	display: block;
	background-image: ${(props) => `url(${props.image})`};
	background-size: cover;
	opacity: 50%;
`;

export {
	AwardsContainer,
	AwardsHeading,
	AwardsHeadingText,
	AwardsHeadingDescription,
	AwardsDisplay,
	AwardContainer,
	AwardInfo,
	AwardText,
	AwardName,
	AwardBox,
	AwardImage,
};
