import styled from "styled-components";
import { Palette, MediaQuery } from "../constants";

const NavBarWrapper = styled.nav.attrs({
	className: "nav-bar-wrapper",
})`
	position: fixed;
	width: 100%;
	z-index: 100;
	background-color: ${(props) =>
		props.isBlackBg ? `${Palette.BLACK}` : `${Palette.WHITE}`};
	padding: "24px 0";
	border-bottom: ${(props) =>
		props.isBlackBg
			? "0.5px solid #E2E2E2"
			: `0.5px solid ${Palette.GREY_SOFT}`};
	@media ${MediaQuery.tablet} {
		border-bottom: none;
		position: static;
		margin: 24px auto;
	}
`;

const NavBar = styled.nav.attrs({
	className: "nav-bar",
})`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1.1rem;
	padding: 0rem 25px;
	background-color: transparent;
	max-width: 1512px;
	@media ${MediaQuery.laptopM} {
		padding: 0 80px;
		margin: 0 auto;
	}
	@media ${MediaQuery.desktopM} {
		font-size: 1.3rem;
	}
`;

const NavList = styled.ul.attrs({
	className: "nav-list",
})`
	display: flex;
	list-style-type: none;
	padding: 0;
`;

const NavItem = styled.li.attrs({
	className: "nav-item",
})`
	font-weight: 500;
	color: ${(props) => {
		if (props.isNavBarBgBlack) {
			if (props.featured) {
				return Palette.WHITE;
			}
			return Palette.GREY_MED;
		}
		if (props.featured) {
			return Palette.BLACK;
		}
		return Palette.GREY_MED;
	}};
	margin-right: 0.188rem;
	padding: 0.2rem;
	border-bottom: ${(props) =>
		props.featured === true
			? `1.5px solid ${props.isNavBarBgBlack ? Palette.WHITE : Palette.BLACK}`
			: "none"};
	&:hover {
		color: ${(props) =>
			props.isNavBarBgBlack ? Palette.WHITE : Palette.BLACK};
	}
	@media ${MediaQuery.tablet} {
		margin-right: 2.5rem;
	}
	@media ${MediaQuery.desktopM} {
		margin-right: 4.375rem;
	}
`;

const ConnectButton = styled.button.attrs({
	className: "connect-button",
})`
	display: flex;
	align-items: center;
	background-color: ${(props) => {
		if (props.buttonColor === "light") {
			if (props.isBlackBg) {
				return Palette.BLACK;
			}
			return Palette.WHITE;
		}
		return Palette.WHITE;
	}};
	color: ${(props) => {
		if (props.buttonColor === "light") {
			if (props.isBlackBg) {
				return Palette.WHITE;
			}
			return Palette.BLACK;
		}
		return Palette.BLACK;
	}};
	height: 3rem;
	border: 2px solid;
	padding: 12px 24px;
	${(props) => (props.isBlackBg ? `${Palette.WHITE}` : `${Palette.BLACK}`)};
	border-radius: 0.2rem;
	cursor: none;
	overflow: hidden;
	@media ${MediaQuery.tablet} {
		padding: 0 1.8rem;
		font-size: 1.2rem;
	}
`;

const MobileNav = styled.div.attrs({
	className: "mobile-nav",
})`
	padding: ${(props) => (props.mobileMenu ? "24px 0" : "24px")};
`;

const MobileNavCircle = styled.div.attrs({
	className: "arrow-circle",
})`
	width: 44px;
	height: 44px;
	border: 1px solid
		${(props) =>
			props.menuColor === "dark" ? `${Palette.WHITE}` : `${Palette.BLACK}`};
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.6rem;
	color: ${(props) =>
		props.menuColor === "dark" ? `${Palette.WHITE}` : `${Palette.BLACK}`};
`;

const MobileNavDisplay = styled.div.attrs({
	className: "mobile-nav-display",
})``;

const MobileNavList = styled.ul.attrs({
	className: "nav-list",
})`
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	list-style-type: none;
	padding: 20% 0 0 0;
	gap: 5rem;
	justify-content: start;
	align-items: center;
	opacity: 0;
	transform: translateY(-100%);
	animation: ${(props) =>
		props.mobileMenu
			? "mobile-menu-appear 0.5s ease-in-out forwards"
			: "mobile-menu-disappear 0.5s ease-in-out forwards"};
`;

// NavButton will be disabled when disabled prop is passed as true and when active and disabled change color to white
const NavButton = styled.button.attrs({
	className: "nav-button",
})`
	color: ${({ isBlackBg, isSelected }) => {
		if (isSelected) {
			if (isBlackBg) {
				return Palette.WHITE;
			}
			return Palette.GREY_DARK;
		}
		return isBlackBg ? Palette.GREY_DARK : Palette.GREY_MED;
	}};

	border: none;
	font-weight: bold;
	font-size: 2rem;
	background-color: transparent;
	transition: 400ms linear 0s;
	&:hover {
		color: ${Palette.GREY_MID};
	}
	${(props) => props.disabled && "disabled: true"};
	&:active {
		color: ${(props) => (props.isBlackBg ? Palette.WHITE : Palette.LIME)};
	}

	&:disabled {
		color: ${Palette.GREY_DARK};
	}
`;

export {
	NavBar,
	NavList,
	NavItem,
	ConnectButton,
	MobileNav,
	MobileNavCircle,
	MobileNavDisplay,
	NavButton,
	MobileNavList,
	NavBarWrapper,
};
