import React from "react";
// import { useOutletContext } from "react-router-dom";
import {
	HomeHeroWrapper,
	GrowWrapper,
	HomeHeroHeader,
	// HomeHeroName
} from "./HomeHeroElements";
import HeroFooter from "./HeroFooter";
import HomeFooter from "./HomeFooter";

export default function HomeHero() {
	// const { mobileMenu } = useOutletContext();

	return (
		<HomeHeroWrapper>
			<HomeHeroHeader>
				<GrowWrapper>Digital Design + Product Strategy</GrowWrapper>
				<HeroFooter />
			</HomeHeroHeader>
			<HomeFooter />
		</HomeHeroWrapper>
	);
}
