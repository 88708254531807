import React from "react";
import styled from "styled-components";
import IMAGES from "../../assets/images";
import { MediaQuery } from "../../constants";

export default function MobileImages({ data }) {
	const { images } = data;
	const isGreyBackground = images.some(
		(image) => image === "poppy_mobile_image_6"
	);
	return (
		<Container
			className='mobile-image-gallery'
			isGreyBackground={isGreyBackground}>
			{images.map((image) => (
				<ImageWrapper
					key={image}
					className='mobile-image-wrapper'>
					<Image
						src={IMAGES[image]}
						alt={`${image}`}
						style={{}}
					/>
				</ImageWrapper>
			))}
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: row;
	overflow-y: scroll;
	padding: 80px 24px;
	gap: 30px;
	background-color: ${({ isGreyBackground }) =>
		isGreyBackground ? "#F4F4F4" : "transparent"};

	::-webkit-scrollbar {
		display: none;
	}

	@media ${MediaQuery.tablet} {
		justify-content: space-around;
		margin: auto auto;
		max-width: 1512px;
	}
`;

const ImageWrapper = styled.div`
	@media ${MediaQuery.tablet} {
		max-width: 25%;
	}
`;

const Image = styled.img.attrs(({ src, alt }) => ({
	src,
	alt,
}))`
	width: 300px;
	max-width: 300px;

	@media ${MediaQuery.tablet} {
		width: 100%;
	}
`;
