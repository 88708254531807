import React from "react";
import styled from "styled-components";

import IMAGES from "../../assets/images";
import { MediaQuery } from "../../constants";
import FloatingImage from "./FloatingImage";

function SectionFloatImage({ data }) {
	const { styles, subcontent, id: sectionId } = data;

	const { relativeHeight, relativeMobileHeight } = styles;

	return (
		<Wrapper
			backgroundColor={styles["background-color"]}
			backgroundImage={styles.background}
			marginBottom={styles["margin-bottom"]}>
			<ImagesContainer
				className={`float-image-container-${sectionId}`}
				relativeHeight={relativeHeight}
				relativeMobileHeight={relativeMobileHeight}>
				{subcontent.map((subContent, index) => (
					<FloatingImage
						key={subContent.id}
						sectionId={sectionId}
						subContent={subContent}
						index={index}
						relativeHeight={relativeHeight}
						relativeMobileHeight={relativeMobileHeight}
					/>
				))}
			</ImagesContainer>
		</Wrapper>
	);
}

const Wrapper = styled.section`
	display: flex;
	justify-content: center;
	background-color: ${(props) => props.backgroundColor || "transparent"};
	background-image: ${(props) =>
		`url(${IMAGES[props.backgroundImage]})` || null};
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	margin-bottom: ${(props) => props.marginBottom || "auto"};
`;

const ImagesContainer = styled.div`
	display: flex;
	position: relative;
	max-width: 1512px;
	width: 100%;
	overflow: hidden;

	&::before {
		content: "";
		display: block;
		padding-bottom: ${(props) =>
			`${(props.relativeMobileHeight / 390) * 100}%` || "auto"};
	}

	@media ${MediaQuery.tablet} {
		&::before {
			content: "";
			display: block;
			padding-bottom: ${(props) =>
				`${(props.relativeHeight / 1512) * 100}%` || "auto"};
		}
	}
`;

export default SectionFloatImage;
