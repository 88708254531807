import React from "react";
import styled from "styled-components";

const Svg = styled.svg.attrs((props) => ({
	style: props.style,
}))`
	width: 18px;
	height: 15.75px;
	margin-left: 10px;

	@media (min-width: 1420px) {
		margin-left: 24px;
		width: 25.83px;
		height: 22.6px;
	}
`;

export default function RightArrow({ style, stroke }) {
	return (
		<Svg
			viewBox='0 0 28 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			style={style}>
			<path
				d='M15.5274 23.5981L26.8265 12.299L15.5274 0.99994M26.8265 12.299L1.00002 12.299'
				stroke={stroke}
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</Svg>
	);
}
