import React from "react";
import {
	AwardContainer,
	AwardInfo,
	AwardText,
	AwardName,
	AwardImage,
	AwardBox,
} from "./AwardElements";
import womenImg from "./Images/women-in-tech-awards-2022-landscape.png";
import g20Img from "./Images/g20-young-entre-landscape.png";
import taraImg from "./Images/tara-awards-cube.png";
import usImg from "./Images/us-patent-awards-cube.png";

export default function AboutMeAwardsElement({
	awardingBody,
	awardName,
	year,
	image,
	color,
	shape,
	setFeaturedAward,
	location,
	featuredAward,
}) {
	const getImage = (imageData) => {
		switch (imageData) {
			case "women":
				return womenImg;
			case "g20":
				return g20Img;
			case "tara":
				return taraImg;
			case "us":
				return usImg;
			default:
				return womenImg;
		}
	};

	return (
		<AwardContainer
			onMouseEnter={() => {
				setFeaturedAward(awardName);
			}}
			onMouseLeave={() => {
				setFeaturedAward("");
			}}>
			<AwardInfo>
				<span>{year}</span>
				<AwardText>
					{awardingBody}
					<AwardName>{awardName}</AwardName>
				</AwardText>
			</AwardInfo>
			{featuredAward === awardName && (
				<AwardBox
					color={color}
					shape={shape}
					location={location}>
					<AwardImage
						image={getImage(image)}
						shape={shape}
					/>
				</AwardBox>
			)}
		</AwardContainer>
	);
}
