import React, { useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import styled from "styled-components";
import { MediaQuery } from "../constants";
import { useLoading } from "../context/LoadingContext";
import PrimaryButton from "../components/button/PrimaryButton";
import achievementsDataJson from "./data/HomeAchievements.json";

function HomeAchievements() {
	gsap.registerPlugin(ScrollTrigger);
	const sectionRef = useRef(null);
	const navigate = useNavigate();
	const { setLoading } = useLoading();

	const content = achievementsDataJson;

	useEffect(() => {
		if (!content.subContent) return;
		setTimeout(() => {
			const containerWidth = content.subContent.length * 500;
			gsap.to(sectionRef.current, {
				x: -containerWidth,
				ease: "none",
				duration: containerWidth / 100,
				repeat: -1,
			});
		}, 500);
	}, [content.subContent.length]);

	const getContent = () => {
		if (!content) {
			return null;
		}

		return (
			<>
				<TitleContainer id='achievements-section'>
					<Title>{content.name}</Title>
					<ButtonContainer>
						<PrimaryButton
							onClick={() => {
								setLoading(true);
								setTimeout(() => {
									setLoading(false);
								}, 3000);
								navigate(`/Press`);
							}}
							title='View Press'
							isBlackBg={false}
							mobileMenu={false}
						/>
					</ButtonContainer>
				</TitleContainer>
				<ParentAchievementsContainer
					id='achievements-carousel'
					ref={sectionRef}
					content={content.subContent}>
					<AchievementsElementContainer
						className='achievements_element_container'
						content={content.subContent}>
						{[
							...content.subContent,
							...content.subContent,
							...content.subContent,
						].map((achievement, index) => (
							<AchievementsElement
								// eslint-disable-next-line react/no-array-index-key
								key={`${index}_${achievement.id}`}
								color={achievement.background_color}
								textColor={achievement.text_color}>
								<LabelYear>{achievement.year}</LabelYear>
								<LabelLocation>{achievement.location}</LabelLocation>
								<LabelTitle>{achievement.title}</LabelTitle>
								{achievement.subtitle ? (
									<LabelSubtitle>{achievement.subtitle}</LabelSubtitle>
								) : null}
							</AchievementsElement>
						))}
					</AchievementsElementContainer>
				</ParentAchievementsContainer>
			</>
		);
	};

	return getContent();
}

const TitleContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 50px 24px;
	background-color: black;

	@media ${MediaQuery.tablet} {
		margin: 0px;
		padding: 80px;
	}
	@media ${MediaQuery.desktopS} {
		max-width: 1512px;
		margin: auto auto;
		padding: 120px 80px 96px;
	}
`;

const Title = styled.div`
	font-size: 24px;
	color: white;
	margin-bottom: 10px;
	@media ${MediaQuery.tablet} {
		margin-bottom: 0;
		font-size: 55px;
	}
`;

const ButtonContainer = styled.div`
	display: none;
	@media ${MediaQuery.tablet} {
		display: block;
	}
`;

const ParentAchievementsContainer = styled.div`
	overflow-x: scroll;
	::-webkit-scrollbar {
		display: none;
	}
	width: ${(props) => `${props.content.length * 1500}px`};
`;
const AchievementsElementContainer = styled.div`
	display: flex;
	margin-left: 24px;
	margin-bottom: 80px;
	position: relative;

	@media ${MediaQuery.tablet} {
		margin-left: 80px;
		margin-bottom: 80px;
	}
`;

const AchievementsElement = styled.div`
	width: 289px;
	height: 232px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-right: 25px;
	border-radius: 15px;
	background-color: ${({ color }) => `${color}`};
	color: ${({ textColor }) => `${textColor}`};
	padding-left: 24px;
	padding-right: 24px;

	@media ${MediaQuery.tablet} {
		width: 405px;
		height: 325px;
	}
`;

const LabelYear = styled.p`
	font-size: 12px;
	margin: 0px;
	@media ${MediaQuery.tablet} {
		font-size: 18px;
	}
`;

const LabelLocation = styled.p`
	font-size: 12px;
	margin-top: 10px;
	margin: 12px 0px 0px 0px;
	@media ${MediaQuery.tablet} {
		font-size: 18px;
	}
`;

const LabelTitle = styled.p`
	font-size: 32px;
	font-weight: 400;
	margin: 5px 0px 0px 0px;
	@media ${MediaQuery.tablet} {
		font-size: 48px;
	}
`;

const LabelSubtitle = styled.p`
	font-size: 8px;
	margin: 10px 0px 0px 0px;
	font-style: italic;
	@media ${MediaQuery.tablet} {
		font-size: 14px;
	}
`;

export default HomeAchievements;
