import React, { useEffect } from "react";
import { BsCheckSquare } from "react-icons/bs";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import IMAGES from "../../assets/images";
import { MediaQuery } from "../../constants";

export default function SectionCardImage({ data }) {
	const { image, underLine, subContent } = data;

	const loadSubContent = (subContentData) => (
		<>
			{subContentData.map((item) => {
				const { title, images, id } = item;
				const desc = item.description;

				useEffect(() => {
					setTimeout(() => {
						gsap.to([`.card-image-container-${id}`], {
							scrollTrigger: {
								trigger: `.card-image-container-${id}`,
								start: "top 75%",
								toggleActions: "play none none none",
							},
							opacity: 1,
							transform: "translateY(0px)",
							duration: 1,
						});
						gsap.to(`.${image}`, {
							scrollTrigger: {
								trigger: `.${image}`,
								start: "top 75%",
								toggleActions: "play none none none",
							},
							opacity: 1,
							transform: "translateY(0px)",
							duration: 1,
						});
					}, 300);
					return () => {
						ScrollTrigger.getAll().forEach((trigger) => {
							trigger.kill();
						});
					};
				}, []);

				return (
					<SubItemContainer
						key={item.title}
						className={`card-image-container-${id}`}>
						<SubItemTitle>
							<SubTitleIcon /> {title}
						</SubItemTitle>
						<SubItemDesc>{desc}</SubItemDesc>
						{images ? (
							<SubImagesContainer>
								{images.map((imageData) => (
									<SubItemImage
										key={imageData}
										src={IMAGES[imageData]}
										imageData={imageData}
										alt='sub item image'
									/>
								))}
							</SubImagesContainer>
						) : null}
					</SubItemContainer>
				);
			})}
		</>
	);

	return (
		<>
			<Container>
				<GroupingContainer>{loadSubContent(subContent)}</GroupingContainer>
				{image && (
					<Image
						src={IMAGES[image]}
						alt='section image'
						className={image}
					/>
				)}
			</Container>
			{underLine && <HorizontalLine />}
		</>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column-reverse;
	margin: 20px 24px;

	@media ${MediaQuery.tablet} {
		flex-direction: row;
		margin: 80px;
	}

	@media ${MediaQuery.desktopM} {
		margin: 80px auto;
		max-width: 1512px;
	}
`;

const HorizontalLine = styled.hr`
	height: 1px;
	background-color: rgba(0, 0, 0, 0.2);
	border: none;
	margin: 0 24px;

	@media ${MediaQuery.tablet} {
		margin: 0 80px;
	}
`;

const GroupingContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	@media ${MediaQuery.tablet} {
		width: 50%;
		margin: 0 40px;
		padding-top: 80px;
	}
`;
const Image = styled.img`
	width: 100%;
	height: 400px;
	margin: 40px 0;
	object-fit: contain;
	opacity: 0;
	transform: translateY(20px);
	@media ${MediaQuery.tablet} {
		width: 50%;
		height: 850px;
		margin: 0;
	}
`;
const SubItemContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 36px;
	opacity: 0;
	transform: translateY(20px);
	@media ${MediaQuery.laptopM} {
		margin-bottom: 60px;
	}
`;

const SubTitleIcon = styled(BsCheckSquare)`
	@media ${MediaQuery.tablet} {
		display: none;
	}
`;

const SubItemTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
	font-size: 1rem;
	font-weight: 400;
	text-transform: capitalize;
	white-space: nowrap;
	margin-bottom: 12px;
	@media ${MediaQuery.tablet} {
		font-size: 2rem;
	}
`;

const SubItemDesc = styled.div`
	font-size: clamp(0.75rem, 2vw, 1.125rem);
	font-weight: 400;
	flex-wrap: wrap;
	margin-bottom: 12px;
	@media ${MediaQuery.tablet} {
		margin-bottom: 24px;
	}
`;

const SubImagesContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 20px;
	flex-wrap: wrap;

	@media ${MediaQuery.tablet} {
		display: none;
	}
`;

const SubItemImage = styled.img`
	z-index: 1;
	object-fit: contain;
	width: 50px;
`;
