import React from "react";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { MobileNav, MobileNavCircle } from "./NavElements";

export default function NavMobile({ mobileMenu, setMobileMenu, isBlackBg }) {
	return (
		<MobileNav mobileMenu>
			<MobileNavCircle
				onClick={() => setMobileMenu((prev) => !prev)}
				menuColor={isBlackBg ? "dark" : "light"}>
				{mobileMenu === false ? (
					<HiOutlineMenuAlt4 strokeWidth={1} />
				) : (
					<AiOutlineClose strokeWidth={1} />
				)}
			</MobileNavCircle>
		</MobileNav>
	);
}
