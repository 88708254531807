import styled from "styled-components";
import { Palette, MediaQuery } from "../../constants";
import RightArrow from "../../assets/SVGs/RightArrow";

function PrimaryButton({
	onClick,
	title,
	isBlackBg,
	mobileMenu,
	hideArrow = false,
}) {
	return (
		<div>
			<ConnectButton
				onClick={onClick}
				isBlackBg={isBlackBg}
				buttonColor={mobileMenu ? "dark" : "light"}>
				<ButtonText>{title}</ButtonText>
				{!hideArrow && (
					<RightArrowIcon
						isBlackBg={isBlackBg}
						mobileMenu={mobileMenu}
					/>
				)}
			</ConnectButton>
		</div>
	);
}

const ConnectButton = styled.div.attrs({
	className: "connect-button",
})`
	display: flex;
	align-items: center;
	font-size: 14px;
	background-color: ${(props) => {
		if (props.buttonColor === "light") {
			if (props.isBlackBg) {
				return Palette.BLACK;
			}
			return Palette.WHITE;
		}
		return Palette.WHITE;
	}};
	color: ${(props) => {
		if (props.buttonColor === "light") {
			if (props.isBlackBg) {
				return Palette.WHITE;
			}
			return Palette.BLACK;
		}
		return Palette.BLACK;
	}};
	border: 3px solid;
	padding: 12px 24px;
	${(props) => (props.isBlackBg ? `${Palette.WHITE}` : `${Palette.BLACK}`)};
	border-radius: 8px;
	cursor: none;
	overflow: hidden;
	width: fit-content;
	@media ${MediaQuery.tablet} {
		padding: 12px 1.8rem;
		font-size: 18px;
		max-height: 22.6px;
	}
	@media ${MediaQuery.laptopL} {
		padding: 24px 48px;
	}
`;

const ButtonText = styled.p`
	margin: 0;
	z-index: 2;
	white-space: nowrap;
`;

const RightArrowIcon = styled(RightArrow).attrs((props) => ({
	stroke: !props.mobileMenu && props.isBlackBg ? Palette.WHITE : Palette.BLACK,
}))``;

export default PrimaryButton;
