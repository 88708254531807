import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { MediaQuery } from "../../constants";

export default function ProjectDetails({ data, style }) {
	const { name, subtitle, description } = data;
	const [projectName] = name.split(" ");

	const location = useLocation();
	const { pathname } = location;

	return (
		<Container
			style={style}
			className={`${projectName}-project-details-container`}>
			<Title name={name}>{name}</Title>
			{subtitle && <SubTitle pathname={pathname}>{subtitle}</SubTitle>}
			{description && <Description>{description}</Description>}
		</Container>
	);
}

const Container = styled.div`
	width: 344px;
	opacity: 0;
	transform: translateY(50px);
	animation:
		fadeIn 0.75s ease-in 0s 1 normal forwards running,
		slideIn 0.5s ease-out 0s 1 normal forwards running;

	@media ${MediaQuery.tablet} {
		width: 527px;
	}
	@media ${MediaQuery.laptopL} {
		width: 621px;
	}
`;

const Title = styled.div`
	font-size: clamp(2rem, 32px, 5.875rem);
	font-weight: 500;
	text-transform: capitalize;
	margin-bottom: 12px;

	@media ${MediaQuery.tablet} {
		font-size: clamp(2rem, 10vw, 94px);
	}
	@media ${MediaQuery.laptopS} {
		padding-left: ${({ name }) => (name === "graphic design" ? "80px" : null)};
	}
`;

const SubTitle = styled.div`
	font-size: clamp(0.75rem, 2vw, 1.5rem);
	line-height: 124%;
	margin: 24px 0;
	display: ${({ pathname }) => (pathname === "/Work/auggy" ? "none" : "block")};
	@media ${MediaQuery.tablet} {
		display: block;
	}
`;

const Description = styled.div`
	font-size: clamp(0.75rem, 2vw, 1.125rem);
	line-height: 150%;
`;
