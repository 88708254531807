import React from "react";
import { HorizontalLine, PressContainer, PressText } from "./PressElements";
import PressContent from "./PressContent";
import data from "./data/data.json";

function PressPage() {
	return (
		<PressContainer>
			<PressText />

			<HorizontalLine />

			<PressContent data={data} />
		</PressContainer>
	);
}

export default PressPage;
