import React from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AboutMeSkillsElement from "../../AboutMe/AboutMeSkillsElement";

import { MediaQuery } from "../../constants";

const SkillsElementContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	padding: 24px;
	justify-content: center;
	background-color: ${(props) => props.backgroundColor || "transparent"};
	opacity: 0;
	transform: translateY(20px);
	@media ${MediaQuery.tablet} {
		flex-direction: row;
		padding: 54px;
	}
`;

const SkillContainer = styled.div`
	width: ${(props) => (props.itemWidth ? `${props.itemWidth}px` : "320px")};
	@media ${MediaQuery.tablet} {
		//flex-direction: row;
		//margin: 54px;
	}
`;

export default function SectionCardSkills({ data }) {
	const { styles, subContent, id } = data;

	React.useEffect(() => {
		setTimeout(() => {
			gsap.to(`.skill-container-${id}`, {
				scrollTrigger: {
					trigger: `.skill-container-${id}`,
					start: "top 75%",
					toggleActions: "play none none none",
				},
				opacity: 1,
				transform: "translateY(0px)",
				duration: 1,
			});
		}, 1000);
		return () => {
			ScrollTrigger.getAll().forEach((trigger) => {
				trigger.kill();
			});
		};
	}, []);

	return (
		<SkillsElementContainer
			backgroundColor={styles && styles.backgroundColor}
			className={`skill-container-${id}`}>
			{subContent.map((content) => (
				<SkillContainer
					key={content.title}
					itemWidth={styles && styles.itemWidth}>
					<AboutMeSkillsElement
						title={content.title}
						description={content.description}
						textColor={styles && styles.textColor}
					/>
				</SkillContainer>
			))}
		</SkillsElementContainer>
	);
}
