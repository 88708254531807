import styled from "styled-components";
import { MediaQuery, Palette } from "../constants";

const centerTextHelper = `
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;`;

const WorkContainer = styled.div.attrs({
	className: "work-container",
})`
	width: 90%;
	margin: auto;
	flex-wrap: wrap;
`;

const WorkImageWrapper = styled.div.attrs({
	className: "work-image-wrapper",
})`
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: 50rem;
	@media ${MediaQuery.tablet} {
		height: 26.25rem;
	}
	@media ${MediaQuery.laptopS} {
		height: clamp(37.5rem, 50vw, 75rem);
	}
`;

const WorkImageColumnContainer = styled.div.attrs({
	className: "work-image-column-container",
})`
	display: flex;
	flex-direction: column;
`;

const WorkImageColumnContainerSm = styled(WorkImageColumnContainer).attrs({
	className: "work-image-column-container-sm",
})`
	flex-basis: 50%;
	@media ${MediaQuery.tablet} {
		flex-basis: 25%;
	}
`;

const WorkImageColumnContainerMed = styled(WorkImageColumnContainer).attrs({
	className: "work-image-column-container-md",
})`
	flex-basis: 50%;
	@media ${MediaQuery.tablet} {
		flex-basis: 32%;
	}
`;

const WorkImageColumnContainerLrg = styled(WorkImageColumnContainer).attrs({
	className: "work-image-column-container-lg",
})`
	flex-basis: 100%;
	@media ${MediaQuery.tablet} {
		flex-basis: 43%;
	}
`;

const WorkImageColumnContainerXLrg = styled.div.attrs({
	className: "work-image-column-container-Xlg",
})`
	flex-basis: 100%;
`;

const WorkImageBox = styled.div.attrs({
	className: "work-image-box",
})`
	background-color: ${Palette.GREY_SOFT};
	border-radius: clamp(0.3rem, 0.6vw, 0.9rem);
	margin: 0.2rem;
	@media ${MediaQuery.tablet} {
		margin: 0.3rem 0.3rem 0.3rem 0.3rem;
	}
`;

const WorkCube = styled(WorkImageBox).attrs({
	className: "work-cube",
})`
	${centerTextHelper}
	height: 80%;
	@media ${MediaQuery.tablet} {
		height: 60%;
	}
`;

const WorkLandscapeMedium = styled(WorkImageBox).attrs({
	className: "work-landscape-medium",
})`
	${centerTextHelper}
	height: 20%;
	@media ${MediaQuery.tablet} {
		height: 40%;
	}
`;

const WorkLandscapeSmall = styled(WorkImageBox).attrs({
	className: "work-landscape-small",
})`
	${centerTextHelper}
	height: 20%;
`;

const WorkVerticalTall = styled(WorkImageBox).attrs({
	className: "work-vertical-tall",
})`
	${centerTextHelper}
	height: 80%;
`;

const WorkLandscapeLarge = styled(WorkImageBox).attrs({
	className: "work-landscape-large",
})`
	${centerTextHelper}
	height: 50%;
`;

const WorkText = styled.div.attrs({
	className: "work-text",
})`
	margin: 3.75rem 0 2.5rem 0.3rem;

	@media ${MediaQuery.tablet} {
		font-size: clamp(1.8rem, 2.5vw, 4rem);
	}
	@media ${MediaQuery.laptopM} {
		margin: 5rem 0 3.75rem 0.3rem;
	}

	@media ${MediaQuery.mbp16} {
		margin: 6.25rem 0 5rem 0.3rem;
	}

	&:after {
		@media ${MediaQuery.tablet} {
			content: "My Work";
		}
	}
`;

const LinkTitle = styled.div`
	font-size: 1.5rem;
	text-transform: capitalize;
	@media ${MediaQuery.tablet} {
		font-size: 2rem;
	}
	@media ${MediaQuery.laptopS} {
		font-size: 3rem;
	}

	@media ${MediaQuery.laptopM} {
		font-size: clamp(3rem, 7vw, 5rem);
	}
`;

export {
	WorkContainer,
	WorkImageWrapper,
	WorkImageColumnContainerSm,
	WorkImageColumnContainerMed,
	WorkImageColumnContainerLrg,
	WorkCube,
	WorkLandscapeMedium,
	WorkLandscapeSmall,
	WorkVerticalTall,
	WorkLandscapeLarge,
	WorkText,
	WorkImageBox,
	LinkTitle,
	WorkImageColumnContainerXLrg,
};
