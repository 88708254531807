import React, { useState } from "react";
import { BsArrowRightShort } from "react-icons/bs";
import styled from "styled-components";
import { MediaQuery } from "../constants";
import { HorizontalLine, PressArticleCard } from "./PressElements";
import { onMouseHoverPress, onMouseLeavePress } from "../Utils/mouseEvents";

function PressContent({ data }) {
	const { articles } = data;

	const [hoveredIndex, setHoveredIndex] = useState(null);

	const animateSVG = () =>
		// index
		{
			// use index for some animation
		};

	const handleMouseEnter = (index) => {
		setHoveredIndex(index);
		onMouseHoverPress();
		animateSVG(index);
	};

	const handleMouseLeave = () => {
		setHoveredIndex(null);
		onMouseLeavePress();
	};

	return (
		<>
			{articles.map((article, index) => (
				<React.Fragment key={article.number}>
					<LinkContainer
						href={article.link}
						target='_blank'
						rel='noopener noreferrer'
						aria-label='Link to article'>
						<PressArticleCard
							onMouseEnter={() => handleMouseEnter(index)}
							onMouseLeave={() => handleMouseLeave()}
							backgroundColor={
								hoveredIndex === index ? article.backgroundColor : ""
							}>
							<NumberContainer isHovered={hoveredIndex === index}>
								{article.number}
							</NumberContainer>
							<ContentContainer>
								<ArticleContent>
									<Author>{article.author}</Author>
									{hoveredIndex !== index ? (
										<Title>{article.title}</Title>
									) : (
										<Content>{article.content}</Content>
									)}
								</ArticleContent>
							</ContentContainer>
						</PressArticleCard>
					</LinkContainer>
					<LinkMobileContainer>
						<NumberMobile>{article.number}</NumberMobile>
						<ContentMobile>
							<AuthorMobile>{article.author}</AuthorMobile>
							<ArticleDescription>{article.content}</ArticleDescription>
							<ArticleLink
								href={article.link}
								target='_blank'
								rel='noopener noreferrer'
								aria-label='Link to article'>
								{article.linkText}
								<RotatedArrow />
							</ArticleLink>
						</ContentMobile>
					</LinkMobileContainer>
					<HorizontalLine />
				</React.Fragment>
			))}
		</>
	);
}

const NumberContainer = styled.div`
	font-size: ${(props) => (props.isHovered ? "5rem" : "3rem")};
	width: 20%;
	margin-top: -20px;
	display: flex;
	align-items: center;
	font-weight: ${(props) => (props.isHovered ? "500" : "400")};
	transition:
		font-size 0.3s ease-in-out,
		font-weight 0.3s ease-in-out;
	@media (max-width: 765px) {
		font-size: ${(props) => (props.isHovered ? "3rem" : "1.5rem")};
	}
`;

const LinkContainer = styled.a`
	text-decoration: none;
	color: inherit;
	display: none;
	@media ${MediaQuery.tablet} {
		display: block;
	}
`;

const ArticleContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 75%;
	@media (max-width: 765px) {
		width: 100%;
	}
`;

const Author = styled.div`
	font-size: 1.125rem;
	font-weight: 400;
	line-height: 124%;
	margin-bottom: 12px;
`;

const Title = styled.div`
	font-size: clamp(1.5rem, 4vw, 3rem);
`;

const Content = styled.div`
	font-size: clamp(0.5rem, 2rem, 1.2rem);
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
`;

const ContentContainer = styled.div`
	display: flex;
	width: 75%;
	justify-content: space-between;
	@media (max-width: 765px) {
	}
`;

const LinkMobileContainer = styled.div`
	color: inherit;
	display: flex;
	gap: 24px;
	@media ${MediaQuery.tablet} {
		display: none;
	}
`;

const NumberMobile = styled.div`
	font-size: 24px;
	padding-top: 24px;
`;

const ContentMobile = styled.div`
	display: flex;
	flex-direction: column;
	padding: 24px 0px;
`;

const AuthorMobile = styled.div`
	font-size: 14px;
`;

const ArticleDescription = styled.p`
	font-size: 12px;
`;

const ArticleLink = styled.a`
	text-decoration: none;
	color: "indigo";
	cursor: pointer;
	font-size: 14px;
	display: flex;
	align-items: center;
	width: fit-content;
	position: relative;

	&:after {
		content: "";
		position: absolute;
		bottom: 4px;
		width: 98%;
		left: 0;
		height: 1px;
		background-color: indigo;
	}
`;

const RotatedArrow = styled(BsArrowRightShort)`
	width: 24px;
	height: 24px;
	transform: rotate(-35deg);
`;

export default PressContent;
