import React from "react";
import styled from "styled-components";
import { DeviceSize, MediaQuery } from "../../constants";

export default function HashLinks({ data }) {
	const { links } = data;
	const scrollToTitle = (link) => {
		const element = document.getElementById(link);
		if (element) {
			element.scrollIntoView({ behavior: "smooth" });
		}
	};
	return (
		<Wrapper>
			{links.map((link) => (
				// eslint-disable-next-line jsx-a11y/anchor-is-valid
				<Link
					key={link}
					id={link}
					onClick={() => {
						scrollToTitle(link.toLowerCase());
					}}>
					{link}
				</Link>
			))}
		</Wrapper>
	);
}

const Wrapper = styled.div.attrs({
	className: "wrapper-has-links",
})`
	margin: 10px auto;
	display: flex;
	@media (max-width: ${DeviceSize.tablet}px) {
		display: none;
	}
`;

const Link = styled.h5`
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 124%;
	cursor: pointer;
	margin: 0px 2.1875rem;
	margin-left: ${(props) => (props.id === "01. Branding" ? "0" : "2.1875rem")};

	text-decoration: underline 0.1em rgba(0, 0, 0, 0);
	transition: text-decoration-color 300ms;
	&:hover {
		text-decoration-color: rgba(0, 0, 0, 1);
	}
	@media ${MediaQuery.laptopS} {
		margin-left: ${(props) => (props.id === "01. Branding" ? "80px" : "1rem")};
	}

	@media ${MediaQuery.laptopM} {
		margin-left: ${(props) =>
			props.id === "01. Branding" ? "80px" : "1.5625rem"};
	}

	@media ${MediaQuery.desktopM} {
		margin-left: ${(props) =>
			props.id === "01. Branding" ? "80px" : "2.1875rem"};
	}
`;
