import styled from "styled-components";
import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { MediaQuery } from "../../constants";

function SectionColorSchemeV2({ data }) {
	const colorsData = data.colors;
	useEffect(() => {
		setTimeout(() => {
			gsap.to("#color-scheme", {
				opacity: 1,
				transform: "translateY(0)",
				duration: 1,
				scrollTrigger: {
					trigger: "#color-scheme",
					start: "top 80%",
				},
			});
			ScrollTrigger.refresh();
		}, 300);
		return () => {
			ScrollTrigger.getAll().forEach((trigger) => {
				trigger.kill();
			});
		};
	}, []);
	return (
		<ColorSchemeContainer>
			{colorsData.map((color) => (
				<ColorPair key={color.name}>
					<ColorsContent color={color} />
				</ColorPair>
			))}
		</ColorSchemeContainer>
	);
}

export default SectionColorSchemeV2;

const ColorSchemeContainer = styled.section.attrs({
	id: "color-scheme",
})`
	display: flex;
	flex-wrap: wrap;
	height: 320px;
	opacity: 0;
	transform: translateY(20px);
	@media ${MediaQuery.tablet} {
		height: 360px;
	}
	@media ${MediaQuery.laptopM} {
		height: 480px;
	}
`;

const ColorPair = styled.div`
	width: 25%;
`;

function ColorsContent({ color }) {
	return (
		<>
			<ColorBlock
				colorData={color}
				backgroundColor={color.primary}
				color={color.color}>
				<p>{color.name}</p>
				<p>{color.primary}</p>
			</ColorBlock>
			<ColorBlock
				colorData={color}
				backgroundColor={color.secondary}
				color={color.secondaryColor}>
				<p>{color.secondaryName}</p>
				<p>{color.secondary}</p>
			</ColorBlock>
		</>
	);
}

const ColorBlock = styled.div`
	color: ${({ color }) => color};
	background-color: ${({ backgroundColor }) => backgroundColor};
	height: 130px;
	padding: 15px 25px;
	font-size: clamp(0.6rem, 1.1vw, 3rem);

	@media ${MediaQuery.tablet} {
		height: 140px;
		padding: 20px 35px;
	}

	@media ${MediaQuery.laptopM} {
		height: 180px;
		padding: 30px 48px;
	}
`;
