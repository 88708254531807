import React from "react";
import styled from "styled-components";
import { MediaQuery } from "../constants";
import IMAGES from "../assets/images";

const HomeFooterLogoContainer = styled.div.attrs({
	className: "home-footer-logo-container",
})`
	display: flex;
	width: 100%;
	opacity: 0;
	transform: translateY(20px);
	max-width: 1512px;
	animation:
		fadeIn 0.75s ease-in 0.8s 1 normal forwards running,
		slideIn 0.5s ease-out 0.8s 1 normal forwards running;

	@media ${MediaQuery.tablet} {
		margin: 48px auto 96px;
		justify-content: space-around;
	}
`;

const HomeFooterLogo = styled.div.attrs({
	className: "home-footer-logo",
})`
	height: 3rem;
	display: none;
	justify-content: center;
	align-items: center;
	margin: 0.4rem;
	@media ${MediaQuery.tablet} {
		display: flex;
		width: calc((100% / 6) - 0.8rem);
	}
	@media ${MediaQuery.laptopS} {
		height: 3.7rem;
	}
	@media ${MediaQuery.laptopM} {
		width: calc((100% / 7) - 0.8rem);
	}
	@media ${MediaQuery.laptopL} {
		height: 4.5rem;
	}
	@media ${MediaQuery.desktopS} {
		height: 5.25rem;
	}
`;

const ImageContainer = styled.div`
	width: 90%;
	max-width: 180px;
	max-height: 74px;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;

	@media ${MediaQuery.tablet} {
		width: auto;
		height: 100%;
	}
`;
const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
`;

const HomeFooterMobileLogo = styled.div.attrs({
	className: "home-footer-mobile-logo",
})`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	@media ${MediaQuery.tablet} {
		display: none;
	}
`;

export default function HomeFooter() {
	return (
		<HomeFooterLogoContainer>
			<HomeFooterLogo>
				<ImageContainer>
					<Image src={IMAGES.women_in_tech} />
				</ImageContainer>
			</HomeFooterLogo>
			<HomeFooterLogo>
				<ImageContainer>
					<Image src={IMAGES.G20_young_ent} />
				</ImageContainer>
			</HomeFooterLogo>
			<HomeFooterLogo>
				<ImageContainer>
					<Image
						src={IMAGES.betakit}
						style={{ maxWidth: "125px" }}
					/>
				</ImageContainer>
			</HomeFooterLogo>
			<HomeFooterLogo>
				<ImageContainer>
					<Image
						src={IMAGES.womens_bus_awards}
						style={{ maxWidth: "180px" }}
					/>
				</ImageContainer>
			</HomeFooterLogo>
			<HomeFooterLogo>
				<ImageContainer>
					<Image
						src={IMAGES.complex}
						style={{ maxWidth: "100px" }}
					/>
				</ImageContainer>
			</HomeFooterLogo>
			<HomeFooterLogo>
				<ImageContainer>
					<Image
						src={IMAGES.RSM}
						style={{ maxWidth: "125px" }}
					/>
				</ImageContainer>
			</HomeFooterLogo>
			<HomeFooterMobileLogo>
				<Image src={IMAGES.mobile_logo} />
			</HomeFooterMobileLogo>
		</HomeFooterLogoContainer>
	);
}
