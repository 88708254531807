import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import NavMobile from "./NavMobile";
import NavMobileDisplay from "./NavMobileDisplay";
import { NavBar, NavList, NavItem, NavBarWrapper } from "./NavElements";
import { DeviceSize } from "../constants";
import routePath from "../core/router/routePath";
import PrimaryButton from "../components/button/PrimaryButton";

gsap.registerPlugin(ScrollToPlugin);

export default function Nav({ mobileMenu, setMobileMenu, isBlackBg }) {
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const path = pathname ? pathname.slice(1) : "";

	useEffect(() => {
		window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
	}, []);

	const scrollPageToBottom = () => {
		const element = document.documentElement;
		element.scrollIntoView({
			behavior: "smooth",
			block: "end",
			inline: "nearest",
		});
		setMobileMenu(false);
	};

	return (
		<NavBarWrapper isBlackBg={isBlackBg}>
			<NavBar>
				{screenWidth >= DeviceSize.tablet ? (
					<NavList>
						<NavItem
							isNavBarBgBlack={isBlackBg}
							featured={path === ""}
							onClick={() => navigate(routePath.ROOT)}>
							Home
						</NavItem>
						<NavItem
							isNavBarBgBlack={isBlackBg}
							featured={path === routePath.WORK || path.slice(0, 4) === "Work"}
							onClick={() => {
								navigate(routePath.ROOT);
								setTimeout(() => {
									gsap.to(window, {
										duration: 0.8,
										scrollTo: `.selected-works-wrapper`,
									});
								}, 200);
							}}>
							My Work
						</NavItem>
						<NavItem
							isNavBarBgBlack={isBlackBg}
							featured={path === routePath.ABOUT}
							onClick={() => {
								navigate(routePath.ROOT);
								setTimeout(() => {
									gsap.to(window, {
										duration: 0.8,
										scrollTo: `.about-me-hero-container`,
									});
								}, 200);
							}}>
							About Me
						</NavItem>
						<NavItem
							isNavBarBgBlack={isBlackBg}
							featured={path === routePath.PRESS}
							onClick={() => navigate(routePath.PRESS)}>
							Press
						</NavItem>
					</NavList>
				) : (
					<NavMobile
						mobileMenu={mobileMenu}
						setMobileMenu={setMobileMenu}
						isBlackBg={isBlackBg}
					/>
				)}
				<PrimaryButton
					onClick={scrollPageToBottom}
					title='Connect'
					isBlackBg={path.includes(routePath.WORK) || !isBlackBg}
					hideArrow={false}
					mobileMenu={mobileMenu}
				/>
			</NavBar>
			{mobileMenu ? (
				<NavMobileDisplay
					isBlackBg={isBlackBg}
					mobileMenu={mobileMenu}
					setMobileMenu={setMobileMenu}
				/>
			) : null}
		</NavBarWrapper>
	);
}
