import React from "react";

export default function TopRightArrow({ style }) {
	return (
		<svg
			className='top-right-arrow'
			xmlns='http://www.w3.org/2000/svg'
			width='13'
			height='13'
			viewBox='0 0 13 11'
			fill='none'
			style={{ ...style, transform: "translate(12px, 0px)", display: "none" }}>
			<path
				d='M9.87917 10.0435L12 2.79222M12 2.79222L4.49583 0.956543M12 2.79222L1 8.98148'
				stroke='#122EEA'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
