import React from "react";

function HomeSkillImages({ id, color }) {
	const image1 = () => (
		<svg
			width='25'
			height='25'
			viewBox='0 0 25 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='scaling'>
				<path
					id='Vector'
					d='M21.1917 3.19531L9.64088 15.1953'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_2'
					d='M12.5286 3.19531H3.86549V21.1953H21.1917V12.1953'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_3'
					d='M16.3789 3.19531H21.1917V8.19531'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_4'
					d='M14.4537 15.1953H9.64088V10.1953'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
		</svg>
	);

	const image2 = () => (
		<svg
			width='24'
			height='25'
			viewBox='0 0 24 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='command'>
				<path
					id='Vector'
					d='M15 6.19531V18.1953C15 18.7887 15.1759 19.3687 15.5056 19.862C15.8352 20.3554 16.3038 20.7399 16.852 20.967C17.4001 21.194 18.0033 21.2534 18.5853 21.1377C19.1672 21.0219 19.7018 20.7362 20.1213 20.3166C20.5409 19.8971 20.8266 19.3625 20.9424 18.7806C21.0581 18.1986 20.9987 17.5954 20.7716 17.0473C20.5446 16.4991 20.1601 16.0305 19.6667 15.7009C19.1734 15.3713 18.5933 15.1953 18 15.1953H6C5.40666 15.1953 4.82664 15.3713 4.33329 15.7009C3.83994 16.0305 3.45543 16.4991 3.22836 17.0473C3.0013 17.5954 2.94189 18.1986 3.05765 18.7806C3.1734 19.3625 3.45912 19.8971 3.87868 20.3166C4.29824 20.7362 4.83279 21.0219 5.41473 21.1377C5.99667 21.2534 6.59987 21.194 7.14805 20.967C7.69623 20.7399 8.16477 20.3554 8.49441 19.862C8.82405 19.3687 9 18.7887 9 18.1953V6.19531C9 5.60197 8.82405 5.02195 8.49441 4.5286C8.16477 4.03526 7.69623 3.65074 7.14805 3.42368C6.59987 3.19661 5.99667 3.1372 5.41473 3.25296C4.83279 3.36871 4.29824 3.65444 3.87868 4.07399C3.45912 4.49355 3.1734 5.0281 3.05765 5.61004C2.94189 6.19199 3.0013 6.79519 3.22836 7.34336C3.45543 7.89154 3.83994 8.36008 4.33329 8.68972C4.82664 9.01937 5.40666 9.19531 6 9.19531H18C18.5933 9.19531 19.1734 9.01937 19.6667 8.68972C20.1601 8.36008 20.5446 7.89154 20.7716 7.34336C20.9987 6.79519 21.0581 6.19199 20.9424 5.61004C20.8266 5.0281 20.5409 4.49355 20.1213 4.07399C19.7018 3.65444 19.1672 3.36871 18.5853 3.25296C18.0033 3.1372 17.4001 3.19661 16.852 3.42368C16.3038 3.65074 15.8352 4.03526 15.5056 4.5286C15.1759 5.02195 15 5.60197 15 6.19531Z'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
		</svg>
	);

	const image3 = () => (
		<svg
			width='24'
			height='25'
			viewBox='0 0 24 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='frame'>
				<path
					id='Vector'
					d='M22 6.3916H2'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_2'
					d='M22 18.3916H2'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_3'
					d='M6 2.3916V22.3916'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_4'
					d='M18 2.3916V22.3916'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
		</svg>
	);

	const image4 = () => (
		<svg
			width='24'
			height='25'
			viewBox='0 0 24 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='layout'>
				<path
					id='Vector'
					d='M19 3.3916H5C3.89543 3.3916 3 4.28703 3 5.3916V19.3916C3 20.4962 3.89543 21.3916 5 21.3916H19C20.1046 21.3916 21 20.4962 21 19.3916V5.3916C21 4.28703 20.1046 3.3916 19 3.3916Z'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_2'
					d='M3 9.3916H21'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_3'
					d='M9 21.3916V9.3916'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
		</svg>
	);

	const image5 = () => (
		<svg
			width='24'
			height='25'
			viewBox='0 0 24 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='paint-bucket'>
				<path
					id='Vector'
					d='M19 11.5879L11 3.58789L2.4 12.1879C2.03355 12.5617 1.82829 13.0644 1.82829 13.5879C1.82829 14.1114 2.03355 14.614 2.4 14.9879L7.6 20.1879C8.4 20.9879 9.6 20.9879 10.4 20.1879L19 11.5879Z'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_2'
					d='M5 2.58789L10 7.58789'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_3'
					d='M2 13.5879H17'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_4'
					d='M22 20.5879C22 21.1183 21.7893 21.627 21.4142 22.0021C21.0391 22.3772 20.5304 22.5879 20 22.5879C19.4696 22.5879 18.9609 22.3772 18.5858 22.0021C18.2107 21.627 18 21.1183 18 20.5879C18 18.9879 19.7 18.1879 20 16.5879C20.3 18.1879 22 18.9879 22 20.5879Z'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
		</svg>
	);

	const image6 = () => (
		<svg
			width='24'
			height='25'
			viewBox='0 0 24 25'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='mouse-pointer-square'>
				<path
					id='Vector'
					d='M21 11.5879V5.58789C21 5.05746 20.7893 4.54875 20.4142 4.17368C20.0391 3.7986 19.5304 3.58789 19 3.58789H5C4.46957 3.58789 3.96086 3.7986 3.58579 4.17368C3.21071 4.54875 3 5.05746 3 5.58789V19.5879C3 20.1183 3.21071 20.627 3.58579 21.0021C3.96086 21.3772 4.46957 21.5879 5 21.5879H11'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					id='Vector_2'
					d='M12 12.5879L16 22.5879L17.7 18.2879L22 16.5879L12 12.5879Z'
					stroke={color}
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
		</svg>
	);

	const result = () => {
		switch (id) {
			case "1":
				return image1();
			case "2":
				return image2();
			case "3":
				return image3();
			case "4":
				return image4();
			case "5":
				return image5();
			case "6":
				return image6();
			default:
				return null;
		}
	};

	return <>{result()}</>;
}

export default HomeSkillImages;
