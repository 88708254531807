import React, { createContext, useContext, useState, useMemo } from "react";

const LoadingContext = createContext(true);

export function LoadingProvider({ children }) {
	const [loading, setLoading] = useState(true);

	const value = useMemo(() => ({ loading, setLoading }), [loading, setLoading]);

	return (
		<LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
	);
}

export const useLoading = () => useContext(LoadingContext);
