// import React, { useEffect } from "react";
// import { gsap } from "gsap/gsap-core";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useState } from "react";
import HomeHero from "./HomeHero";
import HomeSkills from "./HomeSkills";
import HomeAchievements from "./HomeAchievements";
import SelectedWorks from "./SelectedWorks";
import AboutMeHero from "../AboutMe/AboutMeHero";

export default function Home() {
	const [isScrolling, setIsScrolling] = useState(false);

	useEffect(() => {
		if (!isScrolling) {
			const selectedWorkSection = document.getElementById(
				"selected-work-section"
			);
			if (selectedWorkSection) {
				const { scrollY } = window;
				if (scrollY < 200) {
					// User has scrolled past div2, scroll back to the top
					window.scrollTo({ top: 0, behavior: "smooth" });
				}
			}
		}
	}, [isScrolling]);

	useEffect(() => {
		let timeoutId;

		const handleScroll = () => {
			setIsScrolling(true);

			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => {
				setIsScrolling(false);
			}, 500); // Adjust the timeout value as needed
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			// Remove the scroll event listener when the component unmounts
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);
	return (
		<>
			<HomeHero />
			{/* <ExploreWork /> */}
			<SelectedWorks />
			<AboutMeHero />
			<HomeSkills />
			<HomeAchievements />
		</>
	);
}
