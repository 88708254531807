import styled from "styled-components";
import { Palette, MediaQuery } from "../constants";
import theme from "../theme";

const AboutMeHeroWrapper = styled.div`
	background-color: ${Palette.WHITE};
	width: 100%;
`;
const AboutMeHeroContainer = styled.div.attrs({
	className: "about-me-hero-container",
})`
	display: flex;
	flex-direction: column;
	padding: 80px 24px 60px;
	gap: 48px;
	opacity: 0;
	transform: translateY(20px);
	@media ${MediaQuery.tablet} {
		justify-content: space-between;
		flex-direction: row;
		margin: 0px;
		padding: 75px;
		gap: 29px;
	}
	@media ${MediaQuery.desktopS} {
		margin: 0px auto;
		max-width: 1512px;
	}
`;

const AboutMeName = styled.p.attrs({
	className: "about-me-name",
})`
	font-size: 24px;
	margin: 0 auto;
	@media ${MediaQuery.tablet} {
		margin: 0;
		width: 40vw;
		${theme.textStyles.h4}
	}
`;

const AboutMeDescription = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	font-size: 12px;
	transform: translateY(20px);
	@media ${MediaQuery.tablet} {
		flex: 5;
		font-size: 18px;
	}
`;

const AboutMeParagraph = styled.div.attrs({
	className: "about-me-paragraph",
})`
	@media ${MediaQuery.desktopM} {
		font-size: clamp(1rem, 1.2vw, 1.6rem);
	}
`;

const HorizontalLine = styled.hr`
	height: 1px;
	background-color: rgba(0, 0, 0, 0.2);
	border: none;
	margin: 0 24px;

	@media ${MediaQuery.tablet} {
		margin: 0 auto;
		max-width: 1512px;
	}
`;

export {
	AboutMeHeroContainer,
	AboutMeName,
	AboutMeDescription,
	AboutMeParagraph,
	AboutMeHeroWrapper,
	HorizontalLine,
};
