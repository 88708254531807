import React from "react";
import { gsap } from "gsap";
import { useLocation, useNavigate } from "react-router-dom";
import { MobileNavList, NavButton } from "./NavElements";

export default function NavMobileDisplay({
	mobileMenu,
	setMobileMenu,
	isBlackBg,
}) {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const links = [
		{ title: "Home", url: "/" },
		{ title: "My Work", scrollTo: "selected-works-wrapper" },
		{ title: "About Me", scrollTo: "about-me-hero-container" },
		{ title: "Press", url: "press" },
	];

	const getLinks = (LinksArr) =>
		LinksArr.map((link) => {
			const selectedPath = pathname.split("/")[1];

			return (
				<NavButton
					isSelected={
						selectedPath === link.url ||
						(selectedPath === "" && link.url === "/")
					}
					isBlackBg={isBlackBg}
					onClick={() => {
						setMobileMenu(false);
						if (link.url) {
							navigate(link.url);
						} else {
							navigate("/");
							setTimeout(() => {
								gsap.to(window, {
									duration: 0.8,
									scrollTo: `.${link.scrollTo}`,
								});
							}, 200);
						}
					}}
					key={link.title}>
					{link.title}
				</NavButton>
			);
		});
	return (
		<MobileNavList
			mobileMenu={mobileMenu}
			isBlackBg={isBlackBg}>
			{getLinks(links)}
		</MobileNavList>
	);
}
