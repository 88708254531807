// Generic
import kotlinLogo from "./generic/kotlin_logo.png";
import reactLogo from "./generic/react_logo.png";
import swiftLogo from "./generic/swift_logo.png";

// Auggy
import auggyHero from "./auggy/auggy_hero_image.png";
import auggyHeroMobile from "./auggy/auggy_hero_mobile_image.png";
import auggySection3 from "./auggy/auggy_section_3_image.png";
import auggySection3Mobile from "./auggy/auggy_section_3_image_mobile.png";
import auggySection4 from "./auggy/auggy_section_4_image.png";
import auggySection4Mobile from "./auggy/auggy_section_4_image_mobile.png";
import auggySection5 from "./auggy/auggy_section_5_image.png";
import auggySection5Mobile from "./auggy/auggy_section_5_image_mobile.png";
import auggySection7 from "./auggy/auggy_section_7_image.png";
import auggySection7Mobile from "./auggy/auggy_section_7_image_mobile.png";
import auggySection10 from "./auggy/auggy_section_10_image.png";
import auggySection11 from "./auggy/auggy_section_11_image.png";
import auggySection12 from "./auggy/auggy_section_12_image.png";
import auggySection10new from "./auggy/auggy_section_10_new.png";
import auggyMobileSection1 from "./auggy/auggy_mobile_image_1.png";
import auggyMobileSection2 from "./auggy/auggy_mobile_image_2.png";
import auggyMobileSection3 from "./auggy/auggy_mobile_image_3.png";
import auggyMobileSection4 from "./auggy/auggy_mobile_image_4.png";
import auggyMobileSection5 from "./auggy/auggy_mobile_image_5.png";
import auggyMobileSection6 from "./auggy/auggy_mobile_image_6.png";
import auggyMobileSection7 from "./auggy/auggy_mobile_image_7.png";
import auggyMobileSection8 from "./auggy/auggy_mobile_image_8.png";
import auggyMobileSection9 from "./auggy/auggy_mobile_image_9.png";
import auggyMobileSection10 from "./auggy/auggy_mobile_image_10.png";
import auggyMobileSection11 from "./auggy/auggy_mobile_image_11.png";
import auggyMobileSection12 from "./auggy/auggy_mobile_image_12.png";
import auggyFloatImage1 from "./auggy/auggy_float_image_1.png";
import auggyFloatImage2 from "./auggy/auggy_float_image_2.png";
import auggyFloatImage3 from "./auggy/auggy_float_image_3.png";

// Manzil
import manzilHero from "./manzil/manzil_hero_image.png";
import manzilHeroMobile from "./manzil/manzil_hero_mobile.png";
import manzilBranding from "./manzil/manzil_branding_image.png";
import manzilSection5 from "./manzil/manzil_section_5_image.png";
import manzilSection7 from "./manzil/manzil_section_7_image.png";
import manzilSection9 from "./manzil/manzil_section_9_image.png";
import manzilSolutionSection1 from "./manzil/manzil_solution_image_1.png";
import manzilSolutionSection1Mobile from "./manzil/manzil_solution_image_1_mobile.png";
import manzilSolutionSection3 from "./manzil/manzil_solution_image_3.png";
import manzilMobileSection1 from "./manzil/manzil_mobile_1.png";
import manzilMobileSection2 from "./manzil/manzil_mobile_2.png";
import manzilMobileSection3 from "./manzil/manzil_mobile_3.png";
import manzilMobileSection4 from "./manzil/manzil_mobile_4.png";
import manzilWireframes from "./manzil/manzil_wireframes_image.png";
import manzilFloatImage6 from "./manzil/manzil_float_image_6.png";
import manzilFloatImage7 from "./manzil/manzil_float_image_7.png";
import manzilFloatImage8 from "./manzil/manzil_float_image_8.png";
import manzilFloatImage9 from "./manzil/manzil_float_image_9.png";

// Netcoins
import netcoinsHero from "./netcoins/netcoins_hero_image.png";
import netcoinsHeroMobile from "./netcoins/netcoins_hero_mobile.png";
import netcoinsBranding from "./netcoins/netcoins_branding_image.png";
import netcoinsSection5 from "./netcoins/netcoins_section_5_image.png";
import netcoinsSection5Background from "./netcoins/netcoins_section_5_background.png";
import netcoinsSection7 from "./netcoins/netcoins_section_7_image.png";
import netcoinsSection9 from "./netcoins/netcoins_section_9_image.png";
import netcoinsSection10 from "./netcoins/netcoins_section_10_image.png";
import netcoinsSection12 from "./netcoins/netcoins_section_12_image.png";
import netcoinsSection13 from "./netcoins/netcoins_section_13_image.png";
import netcoinsSection15 from "./netcoins/netcoins_section_15_image.png";
import netcoinsSolutionImage1 from "./netcoins/netcoins_solution_image_1.png";
import netcoinsSolutionImage1Mobile from "./netcoins/netcoins_solution_image_1_mobile.png";
import netcoinsSolutionImage3 from "./netcoins/netcoins_solution_image_3.png";
import netcoinsMobileImage1 from "./netcoins/netcoins_mobile_image_1.png";
import netcoinsMobileImage2 from "./netcoins/netcoins_mobile_image_2.png";
import netcoinsMobileImage3 from "./netcoins/netcoins_mobile_image_3.png";
import netcoinsMobileImage4 from "./netcoins/netcoins_mobile_image_4.png";
import netcoinsFloatImage1 from "./netcoins/netcoins_float_image_1.png";
import netcoinsFloatImage1Mobile from "./netcoins/netcoins_float_image_1_mobile.png";
import netcoinsFloatImage2 from "./netcoins/netcoins_float_image_2.png";
import netcoinsFloatImage2Mobile from "./netcoins/netcoins_float_image_2_mobile.png";
import netcoinsFloatImage3 from "./netcoins/netcoins_float_image_3.png";
import netcoinsFloatImage4 from "./netcoins/netcoins_float_image_4.png";
import netcoinsFloatImage4Mobile from "./netcoins/netcoins_float_image_4_mobile.png";
import netcoinsFloatImage5 from "./netcoins/netcoins_float_image_5.png";
import netcoinsFloatImage5Mobile from "./netcoins/netcoins_float_image_5_mobile.png";
import netcoinsFloatImage6 from "./netcoins/netcoins_float_image_6.png";
import netcoinsFloatImage6Mobile from "./netcoins/netcoins_float_image_6_mobile.png";
import netcoinsFloatImage7 from "./netcoins/netcoins_float_image_7.png";
import netcoinsFloatImage7Mobile from "./netcoins/netcoins_float_image_7_mobile.png";
import netcoinsFloatImage8 from "./netcoins/netcoins_float_image_8.png";
import netcoinsFloatImage8Mobile from "./netcoins/netcoins_float_image_8_mobile.png";
import netcoinsFloatImage9 from "./netcoins/netcoins_float_image_9.png";
import netcoinsFloatImage9Mobile from "./netcoins/netcoins_float_image_9_mobile.png";
import netcoinsFloatImage10 from "./netcoins/netcoins_float_image_10.png";
import netcoinsFloatImage10Mobile from "./netcoins/netcoins_float_image_10_mobile.png";
import netcoinsFloatImage11 from "./netcoins/netcoins_float_image_11.png";
import netcoinsFloatImage11Mobile from "./netcoins/netcoins_float_image_11_mobile.png";
import netcoinsFloatImageBackground1 from "./netcoins/netcoins_float_image_background_1.png";
import netcoinsFloatImageBackground2 from "./netcoins/netcoins_float_image_background_2.png";
import netcoinsFloatImageBackground3 from "./netcoins/netcoins_float_image_background_3.png";

// Poppy
import poppyHero from "./poppy/poppy_hero_image.png";
import poppyHeroMobile from "./poppy/poppy_hero_mobile.png";
import poppySection4 from "./poppy/poppy_section_4_image.png";
import poppyMobileImage1 from "./poppy/poppy_mobile_image_1.png";
import poppyMobileImage2 from "./poppy/poppy_mobile_image_2.png";
import poppyMobileImage3 from "./poppy/poppy_mobile_image_3.png";
import poppyMobileImage4 from "./poppy/poppy_mobile_image_4.png";
import poppyMobileImage5 from "./poppy/poppy_mobile_image_5.png";
import poppyMobileImage6 from "./poppy/poppy_mobile_image_6.png";
import poppyMobileImage7 from "./poppy/poppy_mobile_image_7.png";
import poppyMobileImage8 from "./poppy/poppy_mobile_image_8.png";
import poppyMobileImage9 from "./poppy/poppy_mobile_image_9.png";
import poppyMobileImage10 from "./poppy/poppy_mobile_image_10.png";
import poppyDevImage from "./poppy/poppy_dev_image.png";
import poppyDevImageMobile from "./poppy/poppy_dev_image_mobile.png";

// Defient
import defientHero from "./defient/defient_hero_image.png";
import defientHeroMobile from "./defient/defient_hero_mobile.png";
import defientBranding from "./defient/defient_branding_image.png";
import defientSection5 from "./defient/defient_section_5_image.png";
import defientSection7 from "./defient/defient_section_7_image.png";
import defientSection9 from "./defient/defient_section_9_image.png";
import defientMobileImage1 from "./defient/defient_mobile_image_1.png";
import defientMobileImage2 from "./defient/defient_mobile_image_2.png";
import defientMobileImage3 from "./defient/defient_mobile_image_3.png";
import defientMobileImage4 from "./defient/defient_mobile_image_4.png";
import defientMobileImage5 from "./defient/defient_mobile_image_5.png";

// Grata
import grataHero from "./grata/grata_hero_image.png";
import grataHeroMobile from "./grata/grata_hero_mobile.png";
import grataBranding from "./grata/grata_branding_image.png";
import grataSection4 from "./grata/grata_section_4_image.png";
import grataSection4Icon1 from "./grata/grata_section_4_icon_1.png";
import grataSection4Icon2 from "./grata/grata_section_4_icon_2.png";
import grataSection7 from "./grata/grata_section_7_image.png";
import grataSection8 from "./grata/grata_section_8_image.png";
import grataSection8Mobile from "./grata/grata_section_8_image_mobile.png";
import grataSection9 from "./grata/grata_section_9_image.png";
import grataSection9Mobile from "./grata/grata_section_9_image_mobile.png";
import grataSection13 from "./grata/grata_section_13_image.png";
import grataSection13Mobile from "./grata/grata_section_13_image_mobile.png";
import grataSection14 from "./grata/grata_section_14_image.png";
import grataSection14Mobile from "./grata/grata_section_14_image_mobile.png";
import grataSection15 from "./grata/grata_section_15_image.png";
import grataSection15Mobile from "./grata/grata_section_15_image_mobile.png";
import grataSection17 from "./grata/grata_section_17_image.png";
import grataSection17Mobile from "./grata/grata_section_17_image_mobile.png";
import grataSection18 from "./grata/grata_section_18_image.png";
import grataSection18Mobile from "./grata/grata_section_18_image_mobile.png";
import grataSection19 from "./grata/grata_section_19_image.png";
import grataSection19Mobile from "./grata/grata_section_19_image_mobile.png";
import grataSection20 from "./grata/grata_section_20_image.png";
import grataSection21 from "./grata/grata_section_21_image.png";
import grataFloatImage1 from "./grata/grata_float_image_1.png";
import grataFloatImage2 from "./grata/grata_float_image_2.png";
import grataFloatImage3 from "./grata/grata_float_image_3.png";
import grataFloatImage4 from "./grata/grata_float_image_4.png";
import grataFloatImage5 from "./grata/grata_float_image_5.png";
import grataFloatImage6 from "./grata/grata_float_image_6.png";
import grataFloatImage7 from "./grata/grata_float_image_7.png";
import grataFloatImage8 from "./grata/grata_float_image_8.png";

// Graphic Design
import graphicDesignHero from "./graphic-design/graphic_design_hero.png";
import graphicDesignHeroMobile from "./graphic-design/graphic_design_hero_mobile.png";
import graphicDesignSection1 from "./graphic-design/graphic_design_section_1.png";
import graphicDesignSection2 from "./graphic-design/graphic_design_section_2.png";
import graphicDesignSection3 from "./graphic-design/graphic_design_section_3.png";
import graphicDesignSection4 from "./graphic-design/graphic_design_section_4.png";
import graphicDesignSection5 from "./graphic-design/graphic_design_section_5.png";
import graphicDesignSection6 from "./graphic-design/graphic_design_section_6.png";
import graphicDesignSection7 from "./graphic-design/graphic_design_section_7.png";
import graphicDesignSection8 from "./graphic-design/graphic_design_section_8.png";
import graphicDesignSection9 from "./graphic-design/graphic_design_section_9.png";
import graphicDesignSection10 from "./graphic-design/graphic_design_section_10.png";
import graphicDesignSection11 from "./graphic-design/graphic_design_section_11.png";
import graphicDesignSection12 from "./graphic-design/graphic_design_section_12.png";
import graphicDesignSection13 from "./graphic-design/graphic_design_section_13.png";
import graphicDesignSection14 from "./graphic-design/graphic_design_section_14.png";
import graphicDesignSection15 from "./graphic-design/graphic_design_section_15.png";
import graphicDesignSection16 from "./graphic-design/graphic_design_section_16.png";
import graphicDesignSection17 from "./graphic-design/graphic_design_section_17.png";
import graphicDesignSection18 from "./graphic-design/graphic_design_section_18.png";
import graphicDesignSection19 from "./graphic-design/graphic_design_section_19.png";
import graphicDesignSection20 from "./graphic-design/graphic_design_section_20.png";
import graphicDesignSection21 from "./graphic-design/graphic_design_section_21.png";
import graphicDesignSection22 from "./graphic-design/graphic_design_section_22.png";
import graphicDesignSection23 from "./graphic-design/graphic_design_section_23.png";
import graphicDesignSection24 from "./graphic-design/graphic_design_section_24.png";

// call to action (CTA) images
import auggyCta from "./CTA/auggy_cta.png";
import defientCta from "./CTA/defient_cta.png";
import grataCta from "./CTA/grata_cta.png";
import manzilCta from "./CTA/manzil_cta.png";
import netcoinsCta from "./CTA/netcoins_cta.png";
import poppyCta from "./CTA/poppy_cta.png";
import graphicDesignCta from "./CTA/graphic_design_cta.png";

import linkCursor from "./press/link-cursor.png";

// home
import womenInTechLogo from "./home/womeninetch-logo.png";
import G20YoungEntLogo from "./home/G20_young_ent_logo.png";
import betakitLogo from "./home/betakit_logo.png";
import womensBusAwardsLogo from "./home/womens_bus_awards_logo.png";
import complexLogo from "./home/complex_logo.png";
import RSMLogo from "./home/RSM_logo.png";
import mobileLogo from "./home/mobile_logo.png";
import homeCarousel1 from "./home/home_carousel_1.png";
import homeCarousel2 from "./home/home_carousel_2.png";
import homeCarousel3 from "./home/home_carousel_3.png";
import homeCarousel4 from "./home/home_carousel_4.png";
import homeCarousel5 from "./home/home_carousel_5.png";
import homeCarousel6 from "./home/home_carousel_6.png";
import homeCarousel7 from "./home/home_carousel_7.png";

const IMAGES = {
	/* Case Study Pages */

	// Generic
	kotlin_logo: kotlinLogo,
	react_logo: reactLogo,
	swift_logo: swiftLogo,

	// Auggy
	auggy_hero: auggyHero,
	auggy_hero_mobile: auggyHeroMobile,
	auggy_section_3: auggySection3,
	auggy_section_3_mobile: auggySection3Mobile,
	auggy_section_4: auggySection4,
	auggy_section_4_mobile: auggySection4Mobile,
	auggy_section_5: auggySection5,
	auggy_section_5_mobile: auggySection5Mobile,
	auggy_section_7: auggySection7,
	auggy_section_7_mobile: auggySection7Mobile,
	auggy_section_10: auggySection10,
	auggy_section_11: auggySection11,
	auggy_section_12: auggySection12,
	auggy_section_10_new: auggySection10new,
	auggy_mobile_image_1: auggyMobileSection1,
	auggy_mobile_image_2: auggyMobileSection2,
	auggy_mobile_image_3: auggyMobileSection3,
	auggy_mobile_image_4: auggyMobileSection4,
	auggy_mobile_image_5: auggyMobileSection5,
	auggy_mobile_image_6: auggyMobileSection6,
	auggy_mobile_image_7: auggyMobileSection7,
	auggy_mobile_image_8: auggyMobileSection8,
	auggy_mobile_image_9: auggyMobileSection9,
	auggy_mobile_image_10: auggyMobileSection10,
	auggy_mobile_image_11: auggyMobileSection11,
	auggy_mobile_image_12: auggyMobileSection12,
	auggy_float_image_1: auggyFloatImage1,
	auggy_float_image_2: auggyFloatImage2,
	auggy_float_image_3: auggyFloatImage3,

	// Manzil
	manzil_hero: manzilHero,
	manzil_hero_mobile: manzilHeroMobile,
	manzil_branding: manzilBranding,
	manzil_section_5: manzilSection5,
	manzil_section_7: manzilSection7,
	manzil_section_9: manzilSection9,
	manzil_solution_section_1: manzilSolutionSection1,
	manzil_solution_section_1_mobile: manzilSolutionSection1Mobile,
	manzil_solution_section_3: manzilSolutionSection3,
	manzil_mobile_1: manzilMobileSection1,
	manzil_mobile_2: manzilMobileSection2,
	manzil_mobile_3: manzilMobileSection3,
	manzil_mobile_4: manzilMobileSection4,
	manzil_wireframes: manzilWireframes,
	manzil_float_image_6: manzilFloatImage6,
	manzil_float_image_7: manzilFloatImage7,
	manzil_float_image_8: manzilFloatImage8,
	manzil_float_image_9: manzilFloatImage9,

	// Netcoins
	netcoins_hero: netcoinsHero,
	netcoins_hero_mobile: netcoinsHeroMobile,
	netcoins_branding: netcoinsBranding,
	netcoins_section_5: netcoinsSection5,
	netcoins_section_5_background: netcoinsSection5Background,
	netcoins_section_7: netcoinsSection7,
	netcoins_section_9: netcoinsSection9,
	netcoins_section_10: netcoinsSection10,
	netcoins_section_12: netcoinsSection12,
	netcoins_section_13: netcoinsSection13,
	netcoins_section_15: netcoinsSection15,
	netcoins_solution_image_1: netcoinsSolutionImage1,
	netcoins_solution_image_1_mobile: netcoinsSolutionImage1Mobile,
	netcoins_solution_image_3: netcoinsSolutionImage3,
	netcoins_mobile_image_1: netcoinsMobileImage1,
	netcoins_mobile_image_2: netcoinsMobileImage2,
	netcoins_mobile_image_3: netcoinsMobileImage3,
	netcoins_mobile_image_4: netcoinsMobileImage4,
	netcoins_float_image_1: netcoinsFloatImage1,
	netcoins_float_image_1_mobile: netcoinsFloatImage1Mobile,
	netcoins_float_image_2: netcoinsFloatImage2,
	netcoins_float_image_2_mobile: netcoinsFloatImage2Mobile,
	netcoins_float_image_3: netcoinsFloatImage3,
	netcoins_float_image_4: netcoinsFloatImage4,
	netcoins_float_image_4_mobile: netcoinsFloatImage4Mobile,
	netcoins_float_image_5: netcoinsFloatImage5,
	netcoins_float_image_5_mobile: netcoinsFloatImage5Mobile,
	netcoins_float_image_6: netcoinsFloatImage6,
	netcoins_float_image_6_mobile: netcoinsFloatImage6Mobile,
	netcoins_float_image_7: netcoinsFloatImage7,
	netcoins_float_image_7_mobile: netcoinsFloatImage7Mobile,
	netcoins_float_image_8: netcoinsFloatImage8,
	netcoins_float_image_8_mobile: netcoinsFloatImage8Mobile,
	netcoins_float_image_9: netcoinsFloatImage9,
	netcoins_float_image_9_mobile: netcoinsFloatImage9Mobile,
	netcoins_float_image_10: netcoinsFloatImage10,
	netcoins_float_image_10_mobile: netcoinsFloatImage10Mobile,
	netcoins_float_image_11: netcoinsFloatImage11,
	netcoins_float_image_11_mobile: netcoinsFloatImage11Mobile,
	netcoins_float_image_background_1: netcoinsFloatImageBackground1,
	netcoins_float_image_background_2: netcoinsFloatImageBackground2,
	netcoins_float_image_background_3: netcoinsFloatImageBackground3,

	// Poppy
	poppy_hero: poppyHero,
	poppy_hero_mobile: poppyHeroMobile,
	poppy_section_4: poppySection4,
	poppy_dev_image: poppyDevImage,
	poppy_dev_image_mobile: poppyDevImageMobile,
	poppy_mobile_image_1: poppyMobileImage1,
	poppy_mobile_image_2: poppyMobileImage2,
	poppy_mobile_image_3: poppyMobileImage3,
	poppy_mobile_image_4: poppyMobileImage4,
	poppy_mobile_image_5: poppyMobileImage5,
	poppy_mobile_image_6: poppyMobileImage6,
	poppy_mobile_image_7: poppyMobileImage7,
	poppy_mobile_image_8: poppyMobileImage8,
	poppy_mobile_image_9: poppyMobileImage9,
	poppy_mobile_image_10: poppyMobileImage10,

	// Defient
	defient_hero: defientHero,
	defient_hero_mobile: defientHeroMobile,
	defient_branding: defientBranding,
	defient_section_5: defientSection5,
	defient_section_7: defientSection7,
	defient_section_9: defientSection9,
	defient_mobile_image_1: defientMobileImage1,
	defient_mobile_image_2: defientMobileImage2,
	defient_mobile_image_3: defientMobileImage3,
	defient_mobile_image_4: defientMobileImage4,
	defient_mobile_image_5: defientMobileImage5,

	// Grata
	grata_hero: grataHero,
	grata_hero_mobile: grataHeroMobile,
	grata_branding: grataBranding,
	grata_section_4: grataSection4,
	grata_section_4_icon_1: grataSection4Icon1,
	grata_section_4_icon_2: grataSection4Icon2,
	grata_section_7: grataSection7,
	grata_section_8: grataSection8,
	grata_section_8_mobile: grataSection8Mobile,
	grata_section_9: grataSection9,
	grata_section_9_mobile: grataSection9Mobile,
	grata_section_13: grataSection13,
	grata_section_13_mobile: grataSection13Mobile,
	grata_section_14: grataSection14,
	grata_section_14_mobile: grataSection14Mobile,
	grata_section_15: grataSection15,
	grata_section_15_mobile: grataSection15Mobile,
	grata_section_17: grataSection17,
	grata_section_17_mobile: grataSection17Mobile,
	grata_section_18: grataSection18,
	grata_section_18_mobile: grataSection18Mobile,
	grata_section_19: grataSection19,
	grata_section_19_mobile: grataSection19Mobile,
	grata_section_20: grataSection20,
	grata_section_21: grataSection21,
	grata_float_image_1: grataFloatImage1,
	grata_float_image_2: grataFloatImage2,
	grata_float_image_3: grataFloatImage3,
	grata_float_image_4: grataFloatImage4,
	grata_float_image_5: grataFloatImage5,
	grata_float_image_6: grataFloatImage6,
	grata_float_image_7: grataFloatImage7,
	grata_float_image_8: grataFloatImage8,

	// Graphic Design
	"graphic design_hero": graphicDesignHero,
	"graphic design_hero_mobile": graphicDesignHeroMobile,
	graphic_design_section_1: graphicDesignSection1,
	graphic_design_section_2: graphicDesignSection2,
	graphic_design_section_3: graphicDesignSection3,
	graphic_design_section_4: graphicDesignSection4,
	graphic_design_section_5: graphicDesignSection5,
	graphic_design_section_6: graphicDesignSection6,
	graphic_design_section_7: graphicDesignSection7,
	graphic_design_section_8: graphicDesignSection8,
	graphic_design_section_9: graphicDesignSection9,
	graphic_design_section_10: graphicDesignSection10,
	graphic_design_section_11: graphicDesignSection11,
	graphic_design_section_12: graphicDesignSection12,
	graphic_design_section_13: graphicDesignSection13,
	graphic_design_section_14: graphicDesignSection14,
	graphic_design_section_15: graphicDesignSection15,
	graphic_design_section_16: graphicDesignSection16,
	graphic_design_section_17: graphicDesignSection17,
	graphic_design_section_18: graphicDesignSection18,
	graphic_design_section_19: graphicDesignSection19,
	graphic_design_section_20: graphicDesignSection20,
	graphic_design_section_21: graphicDesignSection21,
	graphic_design_section_22: graphicDesignSection22,
	graphic_design_section_23: graphicDesignSection23,
	graphic_design_section_24: graphicDesignSection24,

	// CTA
	auggyCta,
	defientCta,
	grataCta,
	manzilCta,
	netcoinsCta,
	poppyCta,
	graphicDesignCta,

	linkCursor,

	// home
	women_in_tech: womenInTechLogo,
	G20_young_ent: G20YoungEntLogo,
	betakit: betakitLogo,
	womens_bus_awards: womensBusAwardsLogo,
	complex: complexLogo,
	RSM: RSMLogo,
	mobile_logo: mobileLogo,
	home_carousel_1: homeCarousel1,
	home_carousel_2: homeCarousel2,
	home_carousel_3: homeCarousel3,
	home_carousel_4: homeCarousel4,
	home_carousel_5: homeCarousel5,
	home_carousel_6: homeCarousel6,
	home_carousel_7: homeCarousel7,
};

export default IMAGES;
