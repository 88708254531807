import React, { useState } from "react";
import {
	AwardsContainer,
	AwardsHeading,
	AwardsHeadingText,
	AwardsHeadingDescription,
	AwardsDisplay,
} from "./AwardElements";
import AboutMeAwardsElement from "./AboutMeAwardsElement";
import { Palette } from "../constants";

const awardsArray = [
	{
		awardingBody: "Women in Tech Excellence Awards",
		awardName: "Digital Leader of The Year",
		year: "2022",
		image: "women",
		color: `${Palette.LIME_TRANSPARENT}`,
		shape: "landscape",
		location: "lowerLeft",
	},
	{
		awardingBody: "Women in Tech Excellence Awards",
		awardName: "Woman of The Year",
		year: "2022",
		image: "women",
		color: `${Palette.AQUA_TRANSPARENT}`,
		shape: "landscape",
		location: "lowerRight",
	},
	{
		awardingBody: "Women in Tech Excellence Awards",
		awardName: "Transformation Leader",
		year: "2022",
		image: "women",
		color: `${Palette.LILAC_TRANSPARENT}`,
		shape: "landscape",
		location: "upperLeft",
	},
	{
		awardingBody: "United States Patent & Trade Mark Office",
		awardName: "Patent Recipient, Augmented Reality",
		year: "2021",
		image: "us",
		color: `${Palette.INDIGO_TRANSPARENT}`,
		shape: "cube",
		location: "lowerLeftCube",
	},
	{
		awardingBody: "Women in Tech Excellence Awards",
		awardName: "Futurepreneur Delegate",
		year: "2021",
		image: "g20",
		color: `${Palette.LIME_TRANSPARENT}`,
		shape: "landscape",
		location: "lowerRightLong",
	},
	{
		awardingBody: "TARA Multimedia Awards",
		awardName: "Best Podcast",
		year: "2019",
		image: "tara",
		color: `${Palette.AQUA_TRANSPARENT}`,
		shape: "cube",
		location: "centerLeft",
	},
	{
		awardingBody: "TARA Multimedia Awards",
		awardName: "Best Multi-Camera Production",
		year: "2018",
		image: "tara",
		color: `${Palette.LILAC_TRANSPARENT}`,
		shape: "vertical",
		location: "centerLeftVertical",
	},
];

export default function AboutMeSkills() {
	const [featuredAward, setFeaturedAward] = useState("");

	const allAwards = awardsArray.map(
		({ awardingBody, awardName, year, image, color, shape, location }) => (
			<AboutMeAwardsElement
				awardingBody={awardingBody}
				awardName={awardName}
				year={year}
				image={image}
				color={color}
				shape={shape}
				location={location}
				key={`${awardName} ${awardingBody} ${year}`}
				setFeaturedAward={setFeaturedAward}
				featuredAward={featuredAward}
			/>
		)
	);
	return (
		<AwardsContainer>
			<AwardsHeading>
				<AwardsHeadingText>Awards & Achievements</AwardsHeadingText>
				<AwardsHeadingDescription>
					I have been fortunate to have been recognized through awards and
					patents for my work in the technology and digital media sectors.
				</AwardsHeadingDescription>
			</AwardsHeading>
			<AwardsDisplay>{allAwards}</AwardsDisplay>
		</AwardsContainer>
	);
}
