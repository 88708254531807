import React, { useEffect, useMemo, useState } from "react";
import { gsap } from "gsap";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import caseStudiesData from "./data/caseStudies.json";
import CTAData from "./data/CTA.json";
import Hero from "./sections/index";
import {
	SectionCardImage,
	MobileImages,
	SectionCardSkills,
	SectionImage,
	HorizontalCard,
	HashLink,
	SectionColorScheme,
	SectionColorSchemeV2,
	SectionFloatImage,
} from "./components";
import { MediaQuery } from "../constants";
import CTA from "./sections/CTA";

const sectionTypes = {
	CARD: "card",
	IMAGE: "image",
	CARD_IMAGE: "card_image",
	CARD_SKILLS: "card_skills",
	CAROUSEL: "carousel",
	HASHLINKS: "hash_links",
	HASHLINK: "hash_link",
	COLOR_SCHEME: "color_scheme",
	FLOAT_IMAGE: "float_image",
	COLOR_SCHEME_V2: "color_scheme_v2",
};

export function CaseStudyPage() {
	const { caseStudies } = caseStudiesData;
	const { id: currentCaseStudyId } = useParams();
	const getCaseStudyData = useMemo(() => {
		const data = caseStudies.filter(
			(item) => item.project.name === currentCaseStudyId
		);
		return data[0];
	}, [currentCaseStudyId]);
	const navigate = useNavigate();

	const [footerCTA, setFooterCTA] = useState();

	useEffect(() => {
		gsap.to(window, {
			duration: 0.4,
			scrollTo: 0,
			ease: "power2.inOut",
		});
	}, [currentCaseStudyId]);

	useEffect(() => {
		if (!getCaseStudyData) {
			navigate("/");
		}
	}, [getCaseStudyData, navigate]);

	useEffect(() => {
		const { CTAs } = CTAData;
		const number = Math.floor(Math.random() * (CTAs.length - 1));
		const random = CTAs.filter(
			(data) => data.name.toLowerCase() !== currentCaseStudyId
		);
		setFooterCTA(random[number]);
	}, [currentCaseStudyId]);

	return (
		<Container>
			{getCaseStudyData && (
				<>
					<Hero data={getCaseStudyData} />
					{getCaseStudyData.sections.map((section) => {
						switch (section.type) {
							case sectionTypes.CARD:
								return (
									<HorizontalCard
										key={`section-${section.id}`}
										data={section}
									/>
								);

							case sectionTypes.IMAGE:
								return (
									<SectionImage
										key={`section-${section.id}`}
										data={section}
									/>
								);

							case sectionTypes.CARD_IMAGE:
								return (
									<SectionCardImage
										key={`section-${section.id}`}
										data={section}
									/>
								);

							case sectionTypes.CARD_SKILLS:
								return (
									<SectionCardSkills
										key={`section-${section.id}`}
										data={section}
									/>
								);

							case sectionTypes.CAROUSEL:
								return (
									<MobileImages
										key={`section-${section.id}`}
										data={section}
									/>
								);

							case sectionTypes.HASHLINK:
								return (
									<HashLink
										key={`section-${section.id}`}
										data={section}
									/>
								);

							case sectionTypes.COLOR_SCHEME:
								return (
									<SectionColorScheme
										key={`section-${section.id}`}
										data={section}
									/>
								);

							case sectionTypes.FLOAT_IMAGE:
								return (
									<SectionFloatImage
										key={`section-${section.id}`}
										data={section}
									/>
								);
							case sectionTypes.COLOR_SCHEME_V2:
								return (
									<SectionColorSchemeV2
										key={`section-${section.id}`}
										data={section}
									/>
								);

							default:
								return null;
						}
					})}
				</>
			)}
			{footerCTA && getCaseStudyData && <CTA footerCTA={footerCTA} />}
		</Container>
	);
}

const Container = styled.div`
	margin-top: 94px;
	overflow: hidden;

	@media ${MediaQuery.tablet} {
		margin-top: 0px;
	}
`;

export default CaseStudyPage;
