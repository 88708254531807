import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import IMAGES from "../../assets/images";
import PrimaryButton from "../../components/button/PrimaryButton";
import { MediaQuery } from "../../constants";
import { useLoading } from "../../context/LoadingContext";
import theme from "../../theme";

export default function CTA({
	footerCTA: { name, content, buttonColor, background, image, navigate: page },
}) {
	const navigate = useNavigate();
	const { setLoading } = useLoading();
	return (
		<CTABackground
			background={background}
			name={name}>
			<Wrapper>
				<Content>
					<Heading style={{ color: "#000" }}>{name}</Heading>
					<Description style={{ color: "#000" }}>{content}</Description>
					<PrimaryButton
						onClick={() => {
							setLoading(true);
							setTimeout(() => {
								setLoading(false);
							}, 3000);
							navigate(page);
						}}
						title='Check it out'
						isBlackBg={buttonColor === "#000"}
						mobileMenu={false}
						hideArrow
					/>
				</Content>
				<ImageWrapper>
					<Image
						src={IMAGES[image]}
						alt={`${name} call to action image`}
					/>
				</ImageWrapper>
			</Wrapper>
		</CTABackground>
	);
}

const CTABackground = styled.div`
	${({ background, name }) =>
		name !== "Netcoins"
			? `background-color: ${background}`
			: `background: ${background}`};
`;

const Wrapper = styled.div`
	min-height: 400px;
	padding-inline: 80px;
	display: flex;
	justify-content: space-between;
	max-width: 1512px;
	margin: 0 auto;
	@media (max-width: 767px) {
		flex-direction: column;
		height: auto;
		padding-inline: 25px;
	}
`;

const Content = styled.div`
	width: 35%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	align-self: center;
	@media (max-width: 767px) {
		width: 100%;
		margin-bottom: 80px;
		margin-top: 48px;
	}
`;

const Heading = styled.h3`
	font-size: 55px;
	font-style: normal;
	font-weight: 400;
	line-height: 124%;
	color: var(--White, #fff);
	margin: 0px;
	@media ${MediaQuery.tablet} {
		${theme.textStyles.h3}
	}
`;

const Description = styled.h5`
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 124%;
	color: var(--White, #fff);
	margin: 22px 0;
	@media ${MediaQuery.tablet} {
		${theme.textStyles.h5}
	}
`;

const ImageWrapper = styled.div`
	bottom: 0px;
	/* position: absolute; */
	right: 0px;
	padding: inherit;
	display: flex;
	@media (max-width: 767px) {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

const Image = styled.img`
	width: 375px;
	height: auto;
	border-top-right-radius: 20px;
	border-top-left-radius: 20px;
	border: 0px;
	margin-top: 2.5rem;
	@media (max-width: 767px) {
		margin-top: 1rem;
		width: 300px;
	}
`;
