import React from "react";
import { gsap } from "gsap";
import { AiOutlineArrowDown } from "react-icons/ai";
import { HeroFooterContainer, ArrowCircle, TextBox } from "./HomeHeroElements";
import { Palette } from "../constants";
import { onMouseHover, onMouseHoverOut } from "../Utils/mouseEvents";

export default function HeroFooter() {
	return (
		<HeroFooterContainer>
			<ArrowCircle
				onMouseEnter={onMouseHover}
				onMouseLeave={onMouseHoverOut}
				onClick={() => {
					gsap.to(window, {
						duration: 0.8,
						scrollTo: `.selected-works-wrapper`,
					});
				}}>
				<AiOutlineArrowDown style={{ color: Palette.WHITE }} />
			</ArrowCircle>
			<TextBox>
				Multidisciplinary designer and design system expert focused on creating
				beautiful and scalable digital products for your brand
			</TextBox>
		</HeroFooterContainer>
	);
}
