const CaseStudyIds = {
	AUGGY: "auggy",
	MANZIL: "manzil",
	NETCOINS: "netcoins",
	POPPY: "poppy",
	DEFIENT: "defient",
	GRATA: "grata",
	GRAPHICDESIGN: "graphic design",
};

export default CaseStudyIds;
