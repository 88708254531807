import styled from "styled-components";
import { MediaQuery } from "../constants";

export const PressContainer = styled.div.attrs({
	className: "press-container",
})`
	width: 90%;
	margin: auto;
	flex-wrap: wrap;
	margin-bottom: 60px;
	margin-top: 94px;

	@media ${MediaQuery.tablet} {
		margin-bottom: 100px;
		margin-top: 0;
	}
	@media ${MediaQuery.laptopM} {
		margin-bottom: 148px;
	}
`;

export const PressText = styled.div.attrs({
	className: "press-text",
})`
	margin: 40px 0 48px 0.3rem;
	font-size: 32px;
	font-weight: 500;
	@media ${MediaQuery.laptopM} {
		margin: 5rem 0 3.75rem 0.3rem;
		font-size: 55px;
	}

	@media ${MediaQuery.mbp16} {
		margin: 6.25rem 0 5rem 0.3rem;
	}

	&:after {
		content: "Press";
	}
`;

export const PressArticleCard = styled.div.attrs({
	className: "press-article-card",
})`
	padding: 0rem 3rem 0rem 3rem;
	height: 253px;
	display: flex;
	background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const HorizontalLine = styled.hr.attrs({
	className: "hr-line",
})`
	height: 1px;
	background-color: rgba(0, 0, 0, 0.2);
	border: none;

	margin: 0px;
`;
