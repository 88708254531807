import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styled from "styled-components";
import IMAGES from "../../assets/images";
import { MediaQuery } from "../../constants";
import theme from "../../theme";

export default function SectionImage({ data }) {
	const { image, title, styles, background } = data;

	const getImageSrc = () => {
		if (window.matchMedia(`(${MediaQuery.tablet})`).matches) {
			return IMAGES[image];
		}
		return IMAGES[`${image}_mobile`] || IMAGES[image];
	};

	useEffect(() => {
		setTimeout(() => {
			gsap.to(`.section-image-${image}`, {
				opacity: 1,
				transform: "translateY(0px)",
				duration: 1,
				scrollTrigger: {
					trigger: `.section-image-${image}`,
					start: "top 75%",
					toggleActions: "play none none none",
				},
			});
			ScrollTrigger.refresh();
		}, 300);
		return () => {
			ScrollTrigger.getAll().forEach((trigger) => {
				trigger.kill();
			});
		};
	}, []);

	return (
		<Wrapper
			styles={styles}
			className={`section-image-${image}`}
			backgroundColor={styles && styles["background-color"]}
			backgroundImage={background}
			paddingBottom={styles && styles["padding-bottom"]}>
			<ImageContainer>
				{title &&
				(image !== "auggy_section_7" ||
					window.matchMedia(`(${MediaQuery.tablet})`).matches) ? (
					<Title
						titleColor={styles && styles.titleColor}
						underline={styles?.underline}>
						{title}
					</Title>
				) : null}
				{styles?.underline &&
				(image !== "auggy_section_7" ||
					window.matchMedia(`(${MediaQuery.tablet})`).matches) ? (
					<TitleUnderline titleColor={styles && styles.titleColor} />
				) : null}
				{image && (
					<Image
						underline={styles?.underline}
						src={getImageSrc()}
						styles={styles}
						imageString={image}
						alt='auggy section images'
					/>
				)}
			</ImageContainer>
		</Wrapper>
	);
}

const Wrapper = styled.section`
	margin-top: ${(props) => props.styles?.["margin-top"] || "0"};
	background-color: ${(props) => props.backgroundColor || "transparent"};
	background-image: ${(props) =>
		`url(${IMAGES[props.backgroundImage]})` || null};
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	padding-bottom: ${(props) => props.paddingBottom || "0"};
	opacity: 0;
	transform: translateY(20px);
`;

const ImageContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;

	@media ${MediaQuery.laptopL} {
		margin: auto;
		max-width: 1512px;
	}
`;

const Title = styled.div`
	${theme.textStyles.h4}
	text-transform: capitalize;
	white-space: nowrap;
	margin: 50px 24px 25px 24px;
	z-index: 1;
	color: ${(props) => props.titleColor || "black"};
	text-align: start;
	margin-bottom: ${({ underline }) => (underline ? "0" : "48px")};
	@media ${MediaQuery.tablet} {
		margin: 80px 45px 25px 45px;
		margin-bottom: ${({ underline }) => (underline ? "0" : "80px")};
	}
	@media ${MediaQuery.laptopS} {
		align-self: flex-start;
		margin: 80px 0;
		margin-bottom: ${({ underline }) => (underline ? "0" : "80px")};
		font-size: ${({ underline }) => (underline ? "24px" : "48px")};
	}
`;

const TitleUnderline = styled.hr`
	width: 100%;
	height: 2px;
	border: none;
	z-index: 1;
	background-color: ${(props) => props.titleColor || "black"};
`;

const Image = styled.img`
	z-index: 1;
	width: ${({ imageString }) =>
		imageString === "auggy_section_10_new" ? "85%" : "100%"};
	height: auto;
	margin-top: ${({ underline }) => (underline ? "48px" : "0")};
	align-self: center;
	margin-bottom: ${({ styles }) => styles?.["margin-bottom"] || "0"};
	@media ${MediaQuery.tablet} {
		${({ styles }) =>
			styles && Object.entries(styles).map(([key, value]) => `${key}:${value}`)}
		width: ${({ styles }) => styles?.width || "100%"};
		margin-top: ${({ underline }) => (underline ? "80px" : "auto")};
		display: ${({ imageString }) =>
			imageString === "grata_section_21" ? "none" : "block"};
	}
`;
