const Palette = {
	BLACK: "#000",
	WHITE: "#FFFFFF",
	WHITE_TRANSPARENT: "rgba(255, 255, 255, 0)",
	LIME: "#CCEA6C",
	LIME_TRANSPARENT: "#CCEA6C4D",
	INDIGO: "#122EEA",
	INDIGO_TRANSPARENT: "#122EEA8C",
	LILAC: "#EAC4FC",
	LILAC_TRANSPARENT: "#F9EDFE4D",
	AQUA: "#B3E9E3",
	AQUA_TRANSPARENT: "#B9EFEA4D",
	GREY_MED: "#C4C4C4",
	GREY_SOFT: "#F1F1F1",
	GREY_DARK: "#404040",
	GREY_MID: "#6F6F6F",
};

export default Palette;
