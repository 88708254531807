import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DeviceSize, MediaQuery } from "../constants";
import { useLoading } from "../context/LoadingContext";
import IMAGES from "../assets/images";
import {
  onMouseHoverOutCard,
  onMouseHoverOverCard,
} from "../Utils/mouseEvents";
import theme from "../theme";

gsap.registerPlugin(ScrollTrigger);

const images = [
  {
    id: 1,
    image: "home_carousel_1",
    link: "grata",
    text: "Grata Smart Living  / UX + Design System",
  },
  {
    id: 2,
    image: "home_carousel_2",
    link: "poppy",
    text: "Poppy Mobile App / UX Design",
  },
  {
    id: 3,
    image: "home_carousel_3",
    link: "netcoins",
    text: "Netcoins / Branding + Web Design",
  },
  {
    id: 4,
    image: "home_carousel_4",
    link: "auggy",
    text: "Auggy Worlds / Branding + Web Design ",
  },
  {
    id: 5,
    image: "home_carousel_5",
    link: "manzil",
    text: "Manzil Website / Branding + UX Design",
  },
  {
    id: 6,
    image: "home_carousel_6",
    link: "defient",
    text: "Defient AR Experience / UX Design + Patent",
  },
  {
    id: 7,
    image: "home_carousel_7",
    link: "graphic%20design",
    text: "Graphic Design",
  },
];

function SelectedWorks() {
  const [hoveredId, setHoveredId] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const navigate = useNavigate();
  const triggerRef = useRef(null);
  const sectionRef = useRef(null);
  const pinRef = useRef(null);

  const { setLoading } = useLoading();

  const mouseLeaveOnImage = () => {
    setHoveredId(null);
    onMouseHoverOutCard();
  };

  const getTranslateX = () => {
    if (screenWidth >= DeviceSize.desktopM) {
      // return `-${(3000 - screenWidth) / 1.6}px`;
      return "-2400px";
    }
    if (screenWidth >= DeviceSize.desktopS) {
      // return `-${(3000 - screenWidth) / 1.5}px`;
      return "-2600px";
    }
    if (screenWidth >= DeviceSize.laptopM) {
      // return `-${(3000 - screenWidth) / 1.4}px`;
      return "-2800px";
    }
    if (screenWidth >= DeviceSize.laptopS) {
      // return `-${(3000 - screenWidth) / 1.35}px`;
      return "-3000px";
    }
    if (screenWidth >= DeviceSize.tablet) {
      // return `-${(3000 - screenWidth) / 1.3}px`;
      return "-3100px";
    }
    return "-3300px";
  };

  const scrollTriggerPercentage = () => {
    if (screenHeight <= 576) {
      return "top -10%";
    }
    if (screenHeight <= 720) {
      return "top 0%";
    }
    if (screenHeight <= 868) {
      return "top 5%";
    }
    if (screenHeight <= 1080) {
      return "top 20%";
    }
    if (screenHeight <= 1440) {
      return "top 23%";
    }
    return "top 25%";
  };

  useEffect(() => {
    const resize = window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    });

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  useEffect(() => {
    if (screenWidth < DeviceSize.mobileL) {
      ScrollTrigger.getAll().forEach((trigger) => {
        trigger.kill();
      });
    }
  }, [screenWidth]);

  useLayoutEffect(() => {
    if (screenWidth >= DeviceSize.mobileL) {
      gsap.context(() => {
        setTimeout(() => {
          gsap.fromTo(
            sectionRef.current,
            { translateX: 0 },
            {
              translateX: getTranslateX(),
              ease: "none",
              duration: 1,
              scrollTrigger: {
                trigger: triggerRef.current,
                scrub: 0.5,
                start: scrollTriggerPercentage(),
                end: "+=4000",
                pin: [pinRef.current],
              },
            }
          );
        }, 500);
      }, sectionRef);
    }

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => {
        trigger.kill();
      });
    };
  }, []);

  return (
    <PinContainer ref={pinRef}>
      <Wrapper id="selected-work-section">
        <Title>Selected Works</Title>
        <Description>
          Specializing in UX Design, web design and content creation utilizing
          best practices. Here are some of my favourite projects and UX case
          studies.
        </Description>
      </Wrapper>
      <CarouselWrapper ref={triggerRef}>
        <ScrollSection ref={sectionRef}>
          {images.map((carousel) => (
            <Carousel
              key={`carousel_${carousel.id}`}
              onClick={() => {
                onMouseHoverOutCard();
                setLoading(true);
                setTimeout(() => {
                  setLoading(false);
                }, 3000);
                navigate(`/Work/${carousel.link}`);
              }}
            >
              <CarouselImages
                src={IMAGES[carousel.image]}
                onMouseEnter={() => {
                  onMouseHoverOverCard();
                  setHoveredId(carousel.id);
                }}
                lowerSaturation={hoveredId && hoveredId !== carousel.id}
                onMouseLeave={mouseLeaveOnImage}
              />
              <CarouselText
                style={{
                  visibility: hoveredId === carousel.id ? "visible" : "hidden",
                }}
              >
                {carousel.text}
              </CarouselText>
            </Carousel>
          ))}
        </ScrollSection>
      </CarouselWrapper>
      <MobileSlider>
        {images.map((carousel) => (
          <Carousel
            key={`carousel_${carousel.id}`}
            onClick={() => {
              onMouseHoverOutCard();
              setLoading(true);
              setTimeout(() => {
                setLoading(false);
              }, 3000);
              navigate(`/Work/${carousel.link}`);
            }}
          >
            <CarouselImages
              src={IMAGES[carousel.image]}
              onMouseEnter={() => {
                onMouseHoverOverCard();
                setHoveredId(carousel.id);
              }}
              lowerSaturation={hoveredId && hoveredId !== carousel.id}
              onMouseLeave={mouseLeaveOnImage}
            />
            <CarouselText showCarouselText={hoveredId === carousel.id}>
              {carousel.text}
            </CarouselText>
          </Carousel>
        ))}
      </MobileSlider>
    </PinContainer>
  );
}

const PinContainer = styled.div`
  width: 100%;
`;

const Wrapper = styled.div.attrs({
  className: "selected-works-wrapper",
})`
  display: flex;
  padding: 60px 24px 48px 24px;
  align-items: center;
  flex-direction: column;

  @media ${MediaQuery.tablet} {
    flex-direction: row;
    padding: 3rem 5rem;
  }

  @media ${MediaQuery.desktopS} {
    max-width: 1512px;
    margin: auto auto;
  }
`;

const Title = styled.div`
  font-size: clamp(1.5rem, 3vw, 5rem);
  font-weight: 500;
  width: 90%;
  text-transform: capitalize;
  margin-bottom: 12px;
  color: white;
  text-align: center;

  @media ${MediaQuery.tablet} {
    margin-bottom: 0;
    width: 50%;
    text-align: start;
    ${theme.textStyles.h4}
  }
`;

const Description = styled.div`
  font-size: clamp(0.75rem, 2vw, 1.125rem);
  line-height: 150%;
  color: white;
  text-align: center;
  @media ${MediaQuery.tablet} {
    margin-bottom: 0;
    width: 50%;
    text-align: start;
  }
`;

const MobileSlider = styled.div`
  overflow-x: scroll;
  display: flex;
  margin-left: 20px;
  padding-bottom: 80px;
  @media ${MediaQuery.mobileL} {
    display: none;
  }
`;

const CarouselWrapper = styled.div`
  padding: 3rem 12rem;
  overflow-x: hidden;
  display: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media ${MediaQuery.mobileL} {
    display: block;
  }
`;

const ScrollSection = styled.div`
  display: flex;
  width: 2500px;
  position: relative;
`;

const Carousel = styled.div`
  display: flex;
  flex-direction: column;
`;
const CarouselImages = styled.img`
  width: 265;
  height: 351px;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  opacity: ${(props) => (props.lowerSaturation ? "0.7" : "1")};
  margin-right: 1.3rem;
  transition: all 0.5s linear;
  &:hover {
    @media ${MediaQuery.mobileL} {
      opacity: 1;
      scale: 1.1;
      margin-left: 0.7rem;
      margin-right: 2rem;
    }
  }
  @media ${MediaQuery.mobileL} {
    width: 433px;
    height: 574px;
  }
`;

const CarouselText = styled.p`
  visibility: visible;
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  color: white;
  text-align: center;
  @media ${MediaQuery.mobileL} {
    font-size: 1.2rem;
    visibility: ${(props) => (props.showCarouselText ? "visible" : "hidden")};
  }
`;

export default SelectedWorks;
