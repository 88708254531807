import React from "react";
import styled from "styled-components";
import { BsArrowRightShort } from "react-icons/bs";
import { MediaQuery } from "../constants";

const linksConnect = [
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/in/stephanie-loureiro/",
  },
  {
    name: "Instagram",
    href: "https://www.instagram.com/stephloureiro/",
  },

  /**
   * Hidden the personal number
   */
  // {
  //   name: "Phone",
  //   href: "tel:2893802615",
  //   target: null,
  // },
  {
    name: "Email",
    href: "mailto:stephanie@speer.io",
  },
];

function Footer() {
  return (
    <Container>
      <FooterWrapper>
        <LabelTitle>Let&apos;s Connect</LabelTitle>
        <LabelSubtitle>
          If you have a project in mind, need advisory or are looking for a
          strong candidate to fill a UX role, please reach out and let’s
          discuss!
        </LabelSubtitle>
        <ContainerLinks>
          {linksConnect.map((link) => (
            <LinkItem key={link.name} href={link.href} target="_blank">
              {link.name}

              <RotatedArrow />
            </LinkItem>
          ))}
        </ContainerLinks>
      </FooterWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: black;
  padding: 80px 24px;

  @media ${MediaQuery.tablet} {
    flex-direction: column;
    margin: 0px;
    padding: 50px 80px 148px 80px;
  }
`;

const FooterWrapper = styled.div`
  max-width: 1512px;
  margin: 0 auto;
`;

const LabelTitle = styled.p`
  font-size: 40px;
  color: white;
  margin: 0;

  @media ${MediaQuery.tablet} {
    font-size: 94px;
  }
`;

const LabelSubtitle = styled.p`
  font-size: 12px;
  color: white;
  margin: 19px 0px 0px 0px;

  @media ${MediaQuery.tablet} {
    font-size: 24px;
  }
`;

const ContainerLinks = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  margin: 0px;

  @media ${MediaQuery.tablet} {
    flex-direction: row;
    padding: 80px 0px;
  }
`;

const RotatedArrow = styled(BsArrowRightShort)`
  width: 35px;
  height: 35px;
  transform: rotate(-35deg);
  transition: all 0.3s ease-in-out;
  @media ${MediaQuery.tablet} {
    width: 40px;
    height: 40px;
  }
`;

const LinkItem = styled.a`
  font-size: 18px;
  color: white;
  margin: 20px 0px;
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  width: fit-content;

  &:after {
    content: "";
    position: absolute;
    bottom: 8px;
    width: 90%;
    left: 0;
    height: 1px;
    background-color: white;
  }

  &:hover {
    color: #b9efea;
    ${RotatedArrow} {
      transform: rotate(0deg);
      transition: all 0.3s ease-in-out;
    }
    &:after {
      background-color: #b9efea;
    }
  }

  @media ${MediaQuery.tablet} {
    margin: 0px 80px 0px 0px;

    &:after {
      bottom: 9px;
    }
  }
`;

export default Footer;
