import { MediaQuery } from "../constants";

const theme = {
	textStyles: {
		h3: `
			font-size: 55px; 
			font-weight: 400; 
			line-height: 124%;
		`,

		h4: `
			font-size: 24px; 
			font-weight: 400; 
			@media ${MediaQuery.laptopS} {
        font-size: 48px;
    }`,

		h5: `
			font-size: 24px; 
			font-weight: 400; 
			line-height: 124%;
		`,
	},
};

export default theme;
