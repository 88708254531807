import React from "react";
import { WorkContainer, WorkText } from "./WorkElements";
import WorkImageBlock from "./WorkImageBlock";

function Work() {
	return (
		<WorkContainer>
			<WorkText />
			<WorkImageBlock />
		</WorkContainer>
	);
}

export default Work;
